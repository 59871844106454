@import "https://code.highcharts.com/css/highcharts.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/highcharts/7.2.0/css/themes/grid-light.css";

input[type='search'] {
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

body {
  background: #f2f4fc !important;
  font-family: "Inter", sans-serif !important;
  color: #898989;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiButton-root {
  font-family: "Inter", sans-serif !important;
}

header.MuiPaper-root.MuiAppBar-positionFixed.MuiPaper-elevation4 {
  box-shadow: none;
}

.modal {
  top: 50px !important;
}

.selectTable.MuiInputBase-root {
  font-size: 16px;
  font-weight: bold;
  color: #212529;
  margin-top: -5px;
}

.selectTable::before,
.selectTable::after {
  display: none;
}

.selectTable svg {
  color: #212529;
}

.menuToggle {
  float: right;
  margin-top: 10px;
}

.menuFeed {
  width: 90%;
  border: 0 !important;
  padding-bottom: 40px;
  margin-left: 60px;
  padding-top: 15px;
  float: left;
}

.modalchatbot-open {
  height: 40px;
  margin: 10px 0;
  padding: 0px 15px;
  border: none;
  border-radius: 4px;
  background-color: rgb(39, 41, 61);
  cursor: pointer;
}

.modalchatbot-icon {
  width: 20px;
}

.pull-left {
  width: 100%;
  padding: 24px 10px;
  padding-bottom: 0;
  border: 1px solid transparent;
}

.route-minhaconta .headMenu {
  width: 95%;
  display: block;
  float: left;
  justify-content: space-between;
  margin: 2rem auto;
  margin-bottom: 2rem;
}

.headMenuFeed {
  /* float: left; */
  /* width: 90%; */
  /* margin: 2em auto; */
  /* margin-left: 60px; */
}

.boxDarkMode {
  float: left;
}

.menuToggle .linkAccount {
  float: left;
  margin-left: 15px;
  margin-top: -8px;
  line-height: 20px;
  color: #2b2a54;
  font-size: 20px;
}

.menuToggle .linkAccount:hover {
  color: #3de8a0;
}

.boxDarkMode .dark-mode-toggle {
  display: flex;
  margin: 0 auto;
}

.boxDarkMode .dark-mode-toggle > button {
  font-size: 1.2em;
  background: none;
  border: none;
  color: #ffe600;
  cursor: pointer;
  transition: color 0.3s ease;
}

.boxDarkMode .dark-mode-toggle:focus {
  outline: none;
}

.boxDarkMode .toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

.boxDarkMode input[type="checkbox"].dmcheck {
  width: 40px;
  height: 10px;
  background: rgb(237, 237, 237);
  background: linear-gradient(
    90deg,
    rgba(237, 237, 237, 0.8) 0%,
    rgba(128, 128, 128, 0.8) 100%
  );
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;
}

.boxDarkMode input[type="checkbox"].dmcheck:checked + label {
  left: 30px;
}

.boxDarkMode input[type="checkbox"].dmcheck:focus-visible {
  outline: solid 2px white;
}

.boxDarkMode input[type="checkbox"].dmcheck + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  position: absolute;
  left: 2px;
  background: #fff;
  background-color: #fff;
  border: 1px solid #35e098;
  bottom: -1px;
}

body.dark-mode .boxDarkMode input[type="checkbox"].dmcheck + label {
  background: #1e1e2f;
  background-color: #1e1e2f;
  border: 1px solid #35e098;
}

body.dark-mode .boxDarkMode input[type="checkbox"].dmcheck {
  background: rgb(128, 128, 128);
  background: linear-gradient(
    90deg,
    rgba(128, 128, 128, 0.8) 0%,
    rgba(237, 237, 237, 0.8) 100%
  );
}

.metabox-indicator {
  background: #f2f4fc;
  margin: 0;
}

.metabox-indicator .metadataBox {
  background: #fff;
}

.loader {
  padding: 10px 0px 20px 0px;
}

.loader img {
  width: 30px;
  height: 30px;
}

@media (max-width: 1500px) and (min-width: 1024px) {
  .metabox-indicator .metadataBox:last-child .title {
    font-size: 15px;
  }

  .metabox-indicator .metadataBox:last-child .percent {
    font-size: 12px;
  }
}

@media (min-width: 785px) {
  .progress-bar {
    padding: 25px 0px !important;
    width: 576px !important;
  }

  .feed-post .thumbFeed img {
    height: 136px;
  }
}

@media (min-width: 576px) {
  .metabox-indicator .metadataBox.col-sm-2 {
    max-width: 17%;
    flex: 0 0 17%;
  }
}

@media (max-width: 575px) {
  .metabox-indicator .metadataBox.col-sm-2.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .pull-left {
    padding-bottom: 14px;
  }
}

.metabox-indicator .metadataBox:hover .title,
.metabox-indicator .metadataBox:hover .percent,
.metabox-indicator .metadataBox:hover .type,
.metabox-indicator .metadataBox:hover .typeValue,
.metabox-indicator .metadataBox:hover table,
.metabox-indicator .metadataBox:hover table th,
.metabox-indicator .metadataBox:hover .arrowUp,
.metabox-indicator .metadataBox:hover .arrowDown {
  color: #fff !important;
}

.metabox-indicator .metadataBox .title {
  font-size: 16px;
  color: #27224e;
  float: left;
  margin-right: 12px;
}

.metabox-indicator .metadataBox .percent {
  font-size: 13px;
  font-weight: bold;
  margin-top: -2px;
}

.metabox-indicator .metadataBox .arrowUp {
  color: #77d1ab;
}

.metabox-indicator .metadataBox .arrowDown {
  color: #ff4141;
}

.metabox-indicator .metadataBox .speedometer {
  margin-top: 40px;
}

.metabox-indicator .metadataBox .type {
  text-align: center;
  font-weight: 600;
  margin-top: 5px;
  font-size: 22px;
  text-transform: uppercase;
  color: #898989;
  margin-bottom: 0px;
}

.metabox-indicator .metadataBox .typeValue {
  text-align: center;
  font-size: 17px;
  margin-top: -5px;
  margin-bottom: 0;
}

.metabox-indicator .metadataBox .cheap {
  color: #77d1ab;
}

.metabox-indicator .metadataBox .fair {
  color: #a6a9b7;
}

.metabox-indicator .metadataBox .expensive {
  color: #ff4141;
}

.metabox-indicator .metadataBox table {
  color: #a6a9b7;
  font-size: 14px;
}

.metabox-indicator .metadataBox table tr td,
.metabox-indicator .metadataBox table tr th {
  padding-bottom: 5px;
}

.metabox-indicator .metadataBox table th {
  color: #27224e;
  font-size: 16px;
}

.metabox-indicator .metadataBox .item {
  float: left;
  width: 100%;
}

.metabox-indicator .metadataBox .item:first-child {
  margin-bottom: 12px;
}

.metabox-indicator .metadataBox .item .title {
  color: #a6a9b7;
}

.metabox-indicator .metadataBox .value {
  width: 100%;
  float: left;
  margin-top: -10px;
  font-weight: normal;
  margin-bottom: 0;
}

.metabox-indicator .metadataBox .value strong {
  font-weight: bold;
}

.metadataBox {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  color: #adadad;
  border-radius: 6px;
  box-shadow: rgba(0, 4, 77, 0.1) 3px 3px 20px;
  border: 0px !important;
  min-width: 100%;
}

.route-indicadores .indicadores .navTabs {
  margin-bottom: 0;
}

.route-indicadores .indicadores .metabox-indicator {
  margin-top: 0px !important;
  margin-bottom: -5px !important;
  width: 100%;
}

.route-indicadores .indicadores {
  background: none;
}

.route-indicadores .indicadores .App-container {
  border: 0px !important;
  padding: 20px 0px;
}

.metadataBox.priceBox {
  background: none;
  box-shadow: none;
}

.metadataBox .hiddenChart_container {
  position: relative;
}

.metadataBox .hiddenChart_container .paywall {
  position: absolute;
  top: 30%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
}

.metadataBox .hiddenChart_container .paywall .paywall_icon {
  display: block;

  font-size: 2rem;
  margin: 0.5rem auto;
}

.metadataBox .hiddenChart_container .paywall .paywall_btn {
  width: 200px;
  border: none;
  font-family: "Inter", sans-serif;
  font-weight: bold;

  background-image: linear-gradient(to bottom, #fc9f35, #f7348a);
}

.metadataBox .hiddenChart_container .paywall .paywall_text {
  display: block;
  color: #27224e;
  font-size: 12px;
  font-weight: 400;
}

.metadataBox .value {
  font-size: 40px;
  font-weight: bold;
  margin-top: 15px;
  color: #4d4f5c;
}

.metadataBox .value span {
  font-size: 30px;
  margin-left: 10px;
}

.metadataBox:not(.paywall):hover {
  cursor: pointer;
  background: linear-gradient(to right, #679cf6, #4072ee);
  color: #fff;
  box-shadow: rgba(13, 40, 197, 0.4) 0px 3px 10px;
}

.metadataBox:hover .value {
  color: #fff;
}

.modelInputContainer .boxInfo .textGraph svg {
  color: #ffffff;
}

.modelInputContainer .boxInfo .textGraph {
  right: 0;
  bottom: 0;
}

.modelInputContainer .boxInfo {
  font-family: "Inter", sans-serif !important;
  width: 100%;
  position: relative;
  color: #fff;
  font-size: 13px;
}

.boxInfo:hover .textGraph svg {
  color: #fff;
}

.boxInfo .textGraph:hover svg {
  color: #3de8a0;
}

.boxInfo:hover .value {
  color: #fff;
}

.boxInfo .textGraph {
  padding-left: 5px;
  position: absolute;
  right: 14px;
  bottom: 10px;
}

.customLabelControl {
  margin-bottom: 0;
}

.customLabelControl .MuiTypography-root {
  font-size: 13px;
}

.customLabelControl .MuiButtonBase-root {
  padding: 5px 9px;
}

.customInputModels {
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  width: 100% !important;
  font-size: 13px !important;
}

.customInputModels input {
  color: #495057;
  box-shadow: none;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.28rem 0.75rem;
  width: 100%;
}

.customInputModels.MuiInput-underline:before {
  border: 0;
}

.boxInfo .textGraph svg {
  color: rgba(128, 128, 128, 0.377);
  cursor: pointer;
  font-size: 15px;
}

.boxInfo .textGraph .bodyGraph {
  display: none;
}

.boxInfo .textGraph:hover .bodyGraph {
  position: absolute;
  display: block;
  z-index: 999;
  background: #3de8a0;
  border-radius: 4px;
  color: #000;
  margin-top: 2px;
  right: 0;
  width: 250px;
  padding: 10px 10px 0.5px;
  font-size: 12px;
  font-weight: lighter;
  line-height: 16px;
}

.speedometer {
  width: 112px;
  height: 58px;
  position: relative;
  border-radius: 200px 200px 0 0;
  margin: 4px auto 0;
}

.speedometer:after {
  background-color: #fff;
  width: 90px;
  height: 44px;
  left: 18%;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  margin-left: -10px;
  border-radius: 93px 93px 0 0;
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
}

.speedometer > .pointer {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 999;
  left: 50%;
  bottom: 10px;
}

.speedometer > .pointer:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  border-style: solid;
  border-width: 52px 0 0 6px;
  border-color: #000 transparent transparent;
  width: 0;
  height: 0;
}

.speedometer.speed-1 {
  background: linear-gradient(to right, #3ce79f, #f8f24e, #f73787);
  border: 2px solid #000;
}

.menu {
  float: left;
  background: #2b2955 url("./assets/bgMenu.png");
  font-family: "Inter", sans-serif;
  font-size: 0.5em;
  max-width: 271px;
  width: 16%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
}

.menu-items {
  right: 1000;
  position: absolute;
}

.menu .brand a {
  display: inline-block;
  width: auto;
}

.menu .brand a svg {
  height: 52px;
  width: 142px;
}

.menu .brand {
  color: white;
  /* font-size: 30px; */
  /* text-align: center; */
  margin: 2.1rem 2.1rem 4rem 2.1rem;
}

.menu .nav-item {
  padding-left: 1.5em;
  color: #fff;
  margin: 0.7em 0;
  font-size: 1.8em;
}

.menu .nav-link {
  color: #fff;
}

.menu .nav-link a:hover,
.nav-item:hover {
  text-decoration: none !important;
}

.menu .nav-icon {
  font-size: 1.8em;
  margin-right: 0.7rem;
  border: none;
}

.menu .notify {
  background: #3de8a0;
  color: #27224e;
  font-weight: 700;
  font-size: 13px;
  border-radius: 50px;
  padding: 2px 3px;
  margin-left: 35px;
}

.content-body {
  width: 85%;
  min-height: 100vh;
  margin-left: 18%;
  padding-bottom: 5rem;
}

.content-body.route-analise-tecnica .areaUser,
.content-body.route-manual .areaUser {
  display: none;
}

.content-body.route-analise-tecnica,
.content-body.route-manual {
  padding: 0;
}

.content-iframe {
  margin-left: 0px;
}

@media (max-width: 768px) {
  .content-iframe {
    margin-left: 0;
  }
}

.header {
  width: 95%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  /* background: #27224e; */
}

.header .header-icon {
  color: #7e95fc;
}

.header .header-name {
  font-size: 18px;
  color: #7e95fc;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.header .user-info {
  width: 250px;
  margin-top: 2em;
  z-index: 999;
}

.header .user-info.show {
  background: white;
}

.header .btn-conta {
  width: 50%;
  border-radius: 0;
  color: white;
  background: #7e95fc;
  border: none;
  font-size: 16px;
  padding: 0.6em;
  font-weight: bold;
}

.header .btn-logout {
  width: 50%;
  border-radius: 0;
  color: white;
  background: #f56e8d;
  border: none;
  padding: 0.6em;
  font-size: 16px;
  font-weight: bold;
}

#loadingPage {
  width: calc(100% - 271px);
  height: 100vh;
  bottom: 0;
  right: 0;
  position: fixed;
  text-align: center;
  z-index: 999;
  background-color: #ffffff;
}

#loadingPage.visible {
  display: block;
  width: 100%;
}

#loadingPage.hidden {
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  display: none;
}

.loadingHeader {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;
}

.pointProgress.lds-grid {
  position: relative;
  top: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.lds-grid {
  position: absolute;
  /* width: 64px;
  height: 64px; */
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  /* margin-top: -20px; */
  margin: -25px 0 0 -25px;
}

/* .lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #27224e;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
} */

.progress-bar {
  padding: 25px 0;
  width: 100%;
  margin: 0 auto;
}

.progress-bar .MuiLinearProgress-root {
  height: 2px;
  width: 100%;
  background-color: #303355;

}

.progress-bar .MuiLinearProgress-bar {
  width: 10%;
  transform: none !important;
}

.progress-bar .MuiLinearProgress-barColorPrimary {
  background-color: #44E7A4;
}

.progress-bar .progress-bar-icon {
  color: #44E7A4;
  font-size: 32px;
  width: 100%;
}

.post-feed .feed-option {
  font-family: "Inter", sans-serif;
  padding: 0 40px;
  width: 80%;
  margin: 3em auto;
}

.post-feed .feed-option p {
  font-family: "Inter", sans-serif;
  /* line-height: 23px; */
  font-style: normal;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 0;
}

.post-feed .feed-option h4 {
  letter-spacing: -0.01em;
  /* line-height: 52px; */
  color: #fff;
  font-size: 45px;
  margin-bottom: 16px;
  font-weight: 600;
}

.post-feed .feed-option .mt-2 a {
  text-decoration: underline;
  font-family: "inter", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  float: right;
}

.post-feed .feed-option .w-75 svg {
  color: #becff1;
  font-size: 12px;
  /* font-weight: 100;
  margin-left: 0px;
  margin-right: 60px !important;
  transition: all 1s ease-in-out; */
  cursor: pointer;
}

/* .post-feed .feed-option .w-75 svg:hover {
  transform: scale(1.3);
  transition: 1s;
} */

.route-feed .headMenu {
  float: left;
  width: 90%;
  margin: 1rem auto;
  margin-left: 60px;
}

.feed-filter {
  margin-left: auto;
  border-bottom: 1px white solid;
}

.feed-filter-input {
  /* margin-left: auto; */
  color: white;
  background: #27224e;
}

.post-statuses {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.post-badge-label {
  color: #1E1E2F;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  opacity: 0.64;
}

.post-badge {
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding: 4px 8px;
  background-color: #44e7a4;
  border-radius: 4px;
}

.post-badge-free {
  background-color: #ffc831 !important;
}

.post-badge-featured {
  background-color: #fff !important;
}

.feed-posts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

img {
  display: inline-block;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
}

.section-paradigma-tracks {
  margin-bottom: 64px;
  /* max-width: 800px; */
  width: 100%;
  /* height: 550px; */
}

.paradigma-tracks-margin {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  height: 453px;
}

/* .section-paradigma-tracks .paradigma-tracks-center {
  justify-content: center;
} */

/* .section-paradigma-tracks .paradigma-tracks::-webkit-scrollbar {
  display: none;
}  */

.tracks-text {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.tracks-text-margin-top {
  margin-top: 0px;
}

.tracks-text-primary-large {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.tracks-text-primary-x-large {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.04em;
}

.tracks-text-primary-xx-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.section-paradigma-tracks .card-track {
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  margin-right: 30px;
  min-width: 278px;
  max-width: 278px;
  width: 278px;
  height: 417px;
  overflow: hidden;
  border-radius: 6px;
  /* box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.48); */
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.24));
}

.section-paradigma-tracks .card-track-feature {
  max-width: 300px;
  width: 300px;
  height: auto;
  box-shadow: 4px 0px 10px 0px rgba(61, 232, 160, 0.569);
}

.section-paradigma-tracks .card-track-feature .track-image-gradient{
  background: linear-gradient( 
    180deg, 
    rgba(0, 0, 0, 0) 60%, 
    rgba(0, 0, 0, 0.8) 100% 
  ) !important;
}

.section-paradigma-tracks .card-track .track-image-gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.section-paradigma-tracks .card-track .track-container-text {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 24px;
  bottom: 0;
}

.section-paradigma-tracks .card-track button {
  padding: 13px 76px;
  margin-top: 6px;
  width: 100%;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0 0, 0, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.text-filter-blur {
  filter: blur(7px);
}

.section-paradigma-tracks .card-track .soon-text {
  position: absolute;
  z-index: 12;
  text-align: center;
  bottom: 0;
  padding-bottom: 64px;
  width: 100%;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.post-column .feed-post {
  height: 100%;
}

.feed-post {
  /* padding: 1.5em 2.5em; */
  background-color: #27293D;
  margin: 0 10px;
  margin-bottom: 20px;
  /* width: 30%; */
  color: #898989;
  display: flex;
  flex-direction: column;
}

.feed-posts-category .feed-post {
  width: 30%;
}

.feed-posts-category .feed-post .feed-post-date-category {
  justify-content: space-between !important;
  align-items: center !important;
}

.card-post-rightbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-post-rightbar .feed-post .thumbFeed img {
  height: auto !important;
}

.feed-post.post-readed {
  opacity: .55;
}

.footer-detail {
  width: 580px;
  margin: 0 auto 128px auto;
}

.footer-detail .footer-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.footer-detail 
.footer-detail-container 
.footer-detail-veja-tambem {
  display: none;
  margin-left: 9px;
}

.footer-detail 
.footer-detail-container 
.footer-detail-prev {
  display: flex;
}

.footer-detail 
.footer-detail-container 
.footer-detail-prev img {
  padding-right: 2px;
}

.footer-detail 
.footer-detail-container 
.footer-detail-next {
  display: flex;
}

.footer-detail 
.footer-detail-container 
.footer-detail-next img {
  padding-left: 2px;
}

.footer-detail .feed-post .thumbFeed img {
  height: auto !important;
}

.card-post-rightbar .feed-post {
  width: 306px;
}

.footer-detail .feed {
  justify-content: space-between;
  margin-top: 16px;
  gap: 20px;
}

.footer-detail .feed .feed-post {
  width: 100%;
  margin: 0px;
}

.footer-detail .footer-detail-text {
  color: #becff1;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
}

.feed-post .thumbFeed {
  width: 100%;
  /* display: block;
  float: left;
  margin-right: 20px; */
}

.feed-post .thumbFeed img {
  height: auto;
  width: 100%;
}

.feed-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 12px 16px;
}

/* .feed-post:nth-of-type(odd) {
  background: #27293D;
} */

.feed-post-badge {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  align-self: center;
  background: rgba(0, 0, 0, 0.24);
  color: #becff1;
  opacity: 0.64;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  padding: 4px;
  border-radius: 4px;
  /* vertical-align: middle; */
  white-space: nowrap;
}

a.feed-post-badge:hover {
  color: #becff1;
  text-decoration: none;
}

.feed-post-source {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #becff1;
  opacity: 0.64;
}

.feed-post-title a {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  opacity: .80;
  /* color: #becff1; */
}

.feed-post-title a:hover {
  text-decoration: none;
}

.feed-post-date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #becff1;
  opacity: 0.64;
}

.feed-post-date .clap-post {
  display: flex;
  align-items: center;
  gap: 4px;
}

.feed-post-desc {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #becff1;
  opacity: 0.64;
  line-height: 17px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.feed-post-date-container {
  justify-content: flex-end;
}

.headMenu {
  margin-bottom: 40px;
  float: left;
  width: 100%;
}

.route-graficos .headMenu {
  width: 100%;
  float: left;
}

.route-graficos .headMenu .navTabs {
  float: left;
}

.route-modelos .headMenu {
  float: left;
  width: 98%;
  margin-left: 5px;
  margin-bottom: 40px;
  margin-top: 20px;
}

.route-modelos .modelos-container {
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  /* .headMenuFeed {
    margin: 15px 0 -10px 15px;
  } */

  .route-feed .headMenu {
    margin: 0px auto;
    margin-left: 15px;
  }

  .contentIframe .headMenu {
    margin-bottom: 15px;
    margin-top: 0px !important;
    margin-left: 10px;
  }

  .contentIframe .headMenu.headManual {
    margin-bottom: 20px !important;
    margin-top: 35px !important;
  }

  .content.route-manual {
    margin-left: 0;
    width: 100%;
  }
}

/* .postContent {
  color: #27224e;
  padding: 0;
  margin: 0 auto;
} */

.seeAlso {
  color: #27224e;
  text-align: center;
  font-size: 25px;
  width: 100%;
  float: left;
}

.post-feed {
  border: 0 !important;
  margin: .5em auto 2em;
  /* background: #fff; */
}

.post-feed .post {
  width: 80%;
  margin: 3em auto;
}
.post-feed .post .postContent img {
  /* width: 80%; */
  margin: 0 auto;
  display: block;
}

.post-feed .author-pic {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.post .post-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.post .post-info-container .post-author {
  display: flex;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 42px;
}

.post .post-info-container .icons-social-media-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 15px; */
  width: 100%;
  padding: 10px 40px;
  flex-direction: row-reverse;
  border-top: 1px solid rgb(48, 51, 85);
  border-bottom: 1px solid rgb(48, 51, 85);
}

.post .post-info-container .icons-social-media-content .social-media {
  display: flex;
  gap: 15px;
  align-items: center;
}

.post .post-info-container .icons-social-media-content img {
  width: 30px;
  height: 30px;
}

.post .post-info-container .icons-social-media-content img.icon-copy {
  width: 25px;
  height: 25px;
}

.post .author-info {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  
}

.post .date {
  font-family: "Inter", sans-serif;
  /* margin-bottom: 4px; */
  color: #becff1A3;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.64;
}

.post .author {
  display: flex;
  gap: 5;
}

.post .author-name {
  margin-bottom: 2px;
  font-size: 18px;
  /* line-height: 18px; */
  letter-spacing: -0.01em;
  color: #fff;
  font-weight: 400;
}

.post .author .follow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 10px;
  background: transparent;
  /* border: 1px solid #3EE9A1; */
  color: #3EE9A1;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.25s ease;
  cursor: pointer;
}

.post .author .follow-button a {
  color: #3EE9A1;
  text-decoration: none;
}

.post .content-body {
  color: #adadad;
  font-weight: normal;
  font-size: 14px;
}

.areaUser {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
  margin-right: 5%;
  font-family: "Inter", sans-serif;
  max-height: 50px;
}

.areaUser .panelUser li {
  list-style: none;
  cursor: pointer;
  width: 244px;
  position: relative;
  z-index: 2;
}

.areaUser .panelUser li:hover {
  width: 255px;
}

.areaUser .panelUser li:hover .contentUser {
  -webkit-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.52);
  background: #fff;
  border: 1px solid #898989 47;
  border-radius: 3px;
  padding: 10px 10px 20px 10px;
  z-index: 999;
  margin-right: 27px;
  height: 126px;
  margin-top: -11px;
}

.areaUser .panelUser li:hover .subMenu {
  display: block;
  color: #adadad;
  float: left;
  font-size: 14px;
  margin-top: -2px;
}

.areaUser .panelUser li:hover svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 1s;
  margin-top: 11px;
}

.areaUser .panelUser li:hover .imgUser {
  display: block;
  margin-bottom: -15px;
  float: right;
}

.areaUser .panelUser li:hover img {
  float: left;
}

.areaUser .panelUser li:hover .userName {
  display: block;
}

.areaUser .panelUser li .imgUser {
  width: 48px;
  height: 48px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -17px;
}

.areaUser .panelUser li .imgUser img {
  width: 100%;
}

.areaUser .panelUser li svg {
  color: #7e95fc;
  font-size: 22px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 1s;
  float: right;
  margin-left: -5px;
  position: absolute;
  right: 0px;
  top: 14px;
}

.areaUser .panelUser li .userName {
  color: #7e95fc;
  font-size: 14px;
  font-weight: bold;
  float: left;
  margin-top: 15px;
  overflow: hidden;
  width: 137px;
  text-align: right;
  height: 21px;
}

.areaUser .panelUser .subMenu {
  display: none;
  text-align: left;
  width: 223px;
}

.areaUser .panelUser .subMenu .userEmail {
  width: 142px;
  overflow: hidden;
  display: block;
  height: 21px;
  text-align: right;
}

.areaUser .panelUser .subMenu .itensUser {
  padding: 0;
  margin-left: -10px;
  width: 226px;
  margin-top: 15px;
  float: left;
  margin-bottom: -20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.areaUser .panelUser .subMenu .itensUser li {
  height: 44px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  float: left;
  width: 50%;
  position: initial;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.areaUser .panelUser .subMenu .itensUser li a {
  color: #fff;
  text-decoration: none;
}

.areaUser .panelUser .subMenu .itensUser li svg {
  position: initial;
  color: #fff;
  margin-top: 0;
}

.areaUser .panelUser .subMenu .itensUser li:nth-child(1) {
  background: #7e95fc;
  border-bottom-left-radius: 3px;
}

.areaUser .panelUser .subMenu .itensUser li:nth-child(2) {
  border-bottom-right-radius: 3px;
  background: #f26e8d;
}

.btnAssine {
  text-align: center;
  background: #3de8a0;
  color: #27224e !important;
  border-color: #3de8a0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  padding: 6px 0px;
  width: 80%;
  margin: 0 auto;
  /* box-shadow: 1px 3px 7px 0px rgba(220, 220, 220, 0.75); */
}

.areaUser .panelUser .btnAssine:hover {
  background: transparent;
  color: #3de8a0;
}

.areaUser .panelUser .btnLogin {
  background: transparent;
  color: #27224e;
  border-color: #27224e;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  padding: 6px 28px;
  margin-left: 20px;
  box-shadow: 1px 3px 7px 0px rgba(220, 220, 220, 0.75);
}

.areaUser .panelUser .btnLogin:hover {
  background: #27224e;
  color: #fff;
}

.accountContainer {
  width: 100%;
  justify-content: space-between;
  /* margin-top: 3rem; */
}

/* .accountContainer .head {
  position: relative;
} */

/* .accountContainer .head .user {
  font-weight: bold;
  font-size: 38px;
  font-family: "Inter", sans-serif;
  padding-left: 15px;
} */

/* .accountContainer .head .planUser {
  padding-right: 15px;
  display: flex;
  align-self: center;
} */

/* .accountContainer .head .planUser .plan {
  font-size: 16px;
  border: 1px solid #27224e;
  border-radius: 4px;
  padding: 5px 10px;
} */

/* .accountContainer .head .imageUser {
  display: inline-block;
  position: absolute;
  top: 390px;
  left: 16px;
  text-align: center;
  font-size: 80px;
  margin-bottom: -55px;
  position: relative;
  width: 330px;
  margin-top: -10px;
} */

/* .accountContainer .head .imageUser img:first-child {
  width: 20px;
  position: absolute;
  left: -65px;
  top: 10px;
}

.accountContainer .head .imageUser img:last-child {
  width: 50px;
  position: absolute;
  right: 50px;
  bottom: 15px;
} */

.accountContainer .menuItem {
  margin: 30px 0 40px;
}

.accountContainer .menuItem .bgMenu {
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.accountContainer .menuItem .bgMenu:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.accountContainer .account-info .menuItem:nth-child(1) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(1) .bgMenu {
  background-image: linear-gradient(to bottom right, #8500f9, #fc07f3, #0e74ff);
}

.accountContainer .account-info .menuItem:nth-child(2) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(2) .bgMenu {
  background-image: linear-gradient(to bottom right, #fc9f35, #f7348a);
}

.accountContainer .account-info .menuItem:nth-child(3) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(3) .bgMenu {
  background-image: linear-gradient(to bottom right, #5c6ccd, #5753b5);
}

.accountContainer .account-form .menuItem:nth-child(1) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(4) .bgMenu {
  background-image: linear-gradient(to bottom right, #3ce79f, #f8f24e);
}

.accountContainer .account-form .menuItem:nth-child(2) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(5) .bgMenu {
  background-image: linear-gradient(to bottom right, #343295, #904993, #cb357f);
}

.accountContainer .account-form .menuItem:nth-child(3) .bgMenu,
.accountContainer .account-info .slick-slide:nth-child(6) .bgMenu {
  background-image: linear-gradient(to bottom right, #ffa562, #f8f24e);
}

.accountContainer .menuItem a {
  width: 100%;
  height: 94%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  background-image: url("./assets/bg-menuItem@2x.png");
  background-repeat: no-repeat;
  display: block;
  background-size: contain;
  background-position: center;
  padding: 18% 0;
  position: absolute;
}

.accountContainer .menuItem .iconItem {
  font-size: 60px;
  margin-bottom: 0;
}

.accountContainer .menuItem p:last-child {
  margin-top: -3px;
}

.account .account-name,
.account .text-dark,
.account .MuiInputBase-input {
  color: #1e1e2b !important;
  font-weight: normal;
  font-size: 18px;
}

.account .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.account .btnPlan {
  font-family: "Inter", sans-serif !important;
  background-image: linear-gradient(#3ce79f, #f8f24e);
  border: 0px;
  color: #26293b;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 8px 10px;
}

.account .btnPlan:hover {
  background-image: linear-gradient(#f8f24e, #3ce79f);
}

.account .btnContact {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  background: transparent;
  border: 2px solid #1e1e2b;
  color: #1e1e2b;
  font-family: "Inter", sans-serif;
  padding: 6px 10px;
}

.account .btnContact:hover {
  background: #1e1e2b;
  color: #fff;
}

.account button {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  background: #3f51b5;
  border-color: #3f51b5;
  font-weight: bold;
  height: 41px;
  padding: 12px;
}

.account .MuiInputBase-root {
  background: #f2f4fc;
}

/* .account {
  width: 100%;
  padding: 50px;
  margin-top: 20px;
  background-image: url("assets/bgAccount-Light@2x.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.account .account-form .plan {
  border: 1px solid #adadad;
  border-radius: 4px;
  padding: 4px 10px;
  color: #adadad;
  margin-left: 20px;
}

#text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account .MuiFormControl-root {
  width: 100%;
}

.account .MuiInputBase-root {
  width: 100% !important;
  margin-bottom: 15px;
  padding-right: 0;
  border-radius: 4px;
}

.account .account-info {
  width: 100%;
  /* margin-right: 4%; */
  /* justify-content: space-between;
  height: min-content; */
}

.account .account-info .userPhoto {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.account .account-info .userPhoto img {
  border-radius: 4px;
}

.account .account-info .box {
  background: #fff;
  width: 100%;
  float: left;
  padding: 0 1em 1em;
  height: auto;
  margin-bottom: 50px;
}

.account .account-info .box.contact {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.account .account-info .box.contact .btn {
  background: #3de8a0;
  border: #3de8a0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: -18px;
}

.account .account-info .box .progress_pills_container svg {
  width: 76px;
}

.account .account-info .box .complitedNum {
  margin-left: 3.6rem;
  margin-bottom: -1.4rem;
}

.account .account-info .notify {
  position: absolute;
  top: -22px;
  right: -14px;
  background-image: linear-gradient(to bottom right, #fc9f35, #f7348a);
  width: 40px;
  height: 40px;
  align-items: center;
  display: grid;
  border-radius: 100%;
  box-shadow: 5px 5px 10px 0 rgb(229 113 51 / 39%);
}

.account .disableItem {
  position: absolute;
  width: 100%;
  height: 108%;
  top: -4%;
  display: flex;
  align-items: center;
  text-align: center;
  align-content: center;
  border-radius: 12px;
  background: rgb(0 0 0 / 50%);
}

.account .disableItem img {
  padding-left: 25%;
}

/* .accountContainer .circleProgress {
  position: relative;
  width: 190px;
  height: 190px;
  background-color: #1e1e30;
  border-radius: 50%;
  display: inline-block;
} */

/* .accountContainer .circleProgress span {
  position: absolute;
  right: 0;
  left: 0;
  top: 19%;
} */

.account-container .textGraph {
  position: absolute;
  left: 80%;
  top: 50%;
  line-height: 22px;
  margin-left: -8px;
}

.account-container .textGraph svg {
  color: #27224e;
  cursor: pointer;
  font-size: 22px;
}

.account-container .textGraph .bodyGraph {
  display: none;
}

.account-container .textGraph:hover .bodyGraph {
  position: absolute;
  display: block;
  z-index: 999;
  background: #27224e;
  border-radius: 4px;
  color: #fff;
  margin-top: 2px;
  /* right: 0;
  width: 250px; */
  left: 0;
  width: 240px;
  padding: 10px;
  font-size: 12px;
  font-weight: lighter;
  line-height: 16px;
}

.account-name {
  font-size: 22px;
  margin: 0;
  color: #27224e;
  text-align: center;
  font-weight: bold;
}

.account-email {
  font-size: 15px;
  text-align: center;
  margin: 0;
  margin-bottom: 25px;
}

.account .account-form {
  width: 100%;
  background: #fff;
}

.account-form-button {
  height: 41px;
  font-weight: 700;
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
  border-radius: 3px !important;
}

.account-form-button img {
  width: 15px;
}

.typePost {
  display: block;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  float: right;
  padding: 3px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.typePost.type-videos {
  background: #ffa562;
  color: #fff;
}

.typePost.type-news {
  background: #a4b4fd;
  color: #fff;
}

.typePost.type-check {
  background: transparent;
  border: 1px solid #77d1ab;
  color: #77d1ab;
}

.alertBitcoin,
.headerFeed {
  background: #27224e;
  color: #fff;
  font-weight: bold;
  padding: 20px 30px 25px;
  font-family: "Inter", sans-serif;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
  margin-bottom: 0;
}

.alertBitcoin p,
.headerFeed p {
  margin-bottom: 0;
}

.alertBitcoin p .numTwitter,
.headerFeed p .numTwitter,
.alertBitcoin p .colorGreen,
.headerFeed p .colorGreen {
  color: #77d1ab;
  text-decoration: underline;
}

.alertBitcoin p .percentDolar,
.headerFeed p .percentDolar,
.alertBitcoin p .colorRed,
.headerFeed p .colorRed {
  color: #f26e8d;
  text-decoration: underline;
}

#loadingPage {
  width: calc(100% - 271px);
  height: 100vh;
  bottom: 0;
  right: 0;
  position: fixed;
  text-align: center;
  z-index: 999;
  background-color: #fff;
}

#loadingPage.visible {
  display: block;
}

#loadingPage.hidden {
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  display: none;
}

.loadingHeader {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;
}

/* .lds-grid {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  margin-top: -20px;
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #27224e;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
} */

/* // progress style */

.pills {
  display: flex;
  height: 18px;
  align-items: center;
}

.pill-item {
  border-radius: 8px;
  width: 8px;
  height: 16px;
  margin: 0 4px;
  background: #becef4;
}

.pill-item.fill {
  background: #3de8a0;
}

.pill-item.fill.orange {
  background: #ffa562;
}

.pill-item.fill.red {
  background: #f26e8d;
}

.brain_icon {
  text-align: center;
  color: #becef4;
  font-size: 2.5rem;
}

.progress_pills {
  display: flex;
  align-items: center;
}

.progress_text {
  font-size: 16px;
  margin-left: 0.3em;
  color: #3de8a0;
}

.progress_pills_container {
  margin: 0.2em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.complitedNum {
  font-size: 11px;
  border: solid 2px #becef4;
  padding-top: 2px;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  vertical-align: middle;
  text-align: center;
  margin-left: 3rem;
  margin-bottom: -1.7rem;
}

.complitedNum.fill {
  font-size: 11px;
  border: solid 2px #fff;
  background-color: #3de8a0;
  color: #27224e;
}

.content-text img {
  max-width: 100%;
}

.colorFont {
  color: transparent;
}

.graficos-table,
.fundamentos-table {
  text-align: center;
  box-shadow: 1px 3px 7px 0px rgba(220, 220, 220, 0.75);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 16px;
  color: #adadad;
}

.graficos-table .bias {
  border-radius: 6px;
  width: 100px;
  height: 28px;
  color: white;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  letter-spacing: 0.5px;
  line-height: 2.2em;
}

.graficos-table thead tr th {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

.btn-extra {
  background: #f7f7f7;
  border-color: #edeeef;
  padding-left: 24px;
  padding-right: 24px;
  color: #27224e;
}

.btn-extra:hover {
  background: #27224e;
  border-color: #27224e;
  color: #fff;
}

.graficos-table tbody td {
  height: 64px;
}

.fundamentos-table tbody td {
  height: 80px;
}

.graficos-table tbody td,
.graficos-table tbody th,
.fundamentos-table tbody td,
.fundamentos-table tbody th {
  border: 0px;
}

.graficos-table thead tr th,
.graficos-table tbody tr td {
  text-align: left;
}

.graficos-table tbody td {
  background: #f7f7f7;
  font-size: 16px;
  color: #adadad;
  padding: 0;
}

.graficos-table .graficos-detail td {
  background: #fff;
}

/* .graficos-table tbody tr:nth-child(odd) td:nth-child(2n), */
.fundamentos-table tbody tr:nth-child(odd) td:nth-child(2n) {
  background: #fafbff;
}

/* .graficos-table tbody tr:nth-child(odd) td:nth-child(odd), */
.fundamentos-table tbody tr:nth-child(odd) td:nth-child(odd) {
  background: #f2f4fc;
}

/* .graficos-table tbody tr:nth-child(even) td:nth-child(odd), */
.fundamentos-table tbody tr:nth-child(even) td:nth-child(odd) {
  background: #fafbff;
}

.graficos-table tbody tr td:first-child,
.fundamentos-table tbody tr td:first-child {
  position: relative;
  width: 10%;
}

.graficos-table tbody tr td:first-child {
  position: relative;
  width: 5%;
}

.graficos-table tbody tr td:first-child > div {
  fill: #a4b4fd;
  text-align: center;
}

.graficos-table td {
  vertical-align: middle;
}

.graficos-table tbody tr td > div > h6 {
  color: #4d4f5c;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.fundamentos-table tbody tr td:nth-child(2) {
  position: relative;
  width: 14%;
}

.graficos-table tbody tr td:nth-child(2) {
  position: relative;
  width: 10%;
}

.fundamentos-table tbody tr td:nth-child(3) {
  position: relative;
  width: 30%;
}

.graficos-table tbody tr td:nth-child(3) {
  position: relative;
  width: 15%;
}

.fundamentos-table tbody tr td:nth-child(4) {
  position: relative;
  width: 25%;
}

.graficos-table tbody tr td:nth-child(4) {
  position: relative;
  width: 15%;
}

.graficos-table tbody tr td:nth-child(4) .signal {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border-radius: 3px;
}

.fundamentos-table tbody tr td:nth-child(5) {
  position: relative;
  width: 20%;
}

.graficos-table tbody tr td:nth-child(5) {
  position: relative;
  width: 15%;
}

.graficos-table tbody tr td:nth-child(6) {
  position: relative;
  width: 35%;
}

.graficos-table tbody tr td:nth-child(7) {
  position: relative;
  width: 5%;
}

.graficos-table > tbody > tr:not(.graficos-detail) {
  border-bottom: solid 1px #eceeef;
}

.graficos-table tbody tr td:nth-child(2) h5,
.fundamentos-table tbody tr td:nth-child(2) h5 {
  font-weight: bold;
  color: #7e95fc;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  font-size: 16px;
}

/* .graficos-table tbody tr td:nth-child(2) div, */
.fundamentos-table tbody tr td:nth-child(2) div {
  align-items: center;
  justify-content: center;
  display: -webkit-inline-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto !important;
  line-height: 20px;
}

.graficos-table tbody tr td:nth-child(3) div p,
.fundamentos-table tbody tr td:nth-child(3) div p {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.graficos-table tbody tr td:nth-child(2) small,
.fundamentos-table tbody tr td:nth-child(2) small {
  font-size: 14px;
}

.fundamentos-table tbody tr td:nth-child(4) div,
.graficos-table tbody tr td:nth-child(5) .pills,
.fundamentos-table tbody tr td:nth-child(5) .pills {
  align-items: center;
  justify-content: center;
  display: -webkit-inline-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto !important;
}

.graficos-table thead,
.fundamentos-table thead {
  background: #27224e;
  color: #f2f4fc;
}

.graficos-table thead th,
.fundamentos-tablethead th {
  border: none;
}

.graficos-table tbody img,
.fundamentos-table tbody img {
  align-items: center;
  justify-content: center;
  display: -webkit-inline-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: auto !important;
  width: 40px;
}

.graficos-table tbody tr td:nth-child(3) h5 {
  color: #77d1ab;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: -webkit-inline-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto !important;
}

.graficos-table tbody tr td:nth-child(4) p {
  margin-right: 0;
  margin-left: 0;
  left: 50%;
  width: 80%;
  align-items: center;
  justify-content: center;
  display: -webkit-inline-box;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto !important;
}

.graficos-table tbody tr td:nth-child(4) p div {
  width: 36%;
  text-align: left;
}

.graficos-table tbody tr td:nth-child(4) p div span {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  padding: 3px 10px;
  font-weight: normal;
}

.graficos-table tbody tr td:nth-child(4) span {
  display: inline !important;
  font-weight: bold;
}

.graficos-table tbody tr td:nth-child(5) .date {
  font-size: 14px;
}

/* .graficos-table tbody tr td:nth-child(5) h6 {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 16px;
} */

.graficos-table > tbody > tr:nth-of-type(odd) {
  cursor: pointer;
}

.graficos-table > tbody > tr:nth-of-type(odd) td {
  transition: background 0.25s;
}

.graficos-table > tbody > tr:nth-of-type(odd):hover td {
  background: #e9eaf3;
}

.indicators-detail {
  margin: 1em 1.25em;
}

.indicators-detail thead tr th {
  color: #898989;
}

.indicators-detail thead tr th,
.indicators-detail tbody tr td {
  text-align: left;
  font-size: 14px;
  font-weight: 200;
}

.indicators-detail thead tr div.indicators-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #27224e;
}

.indicators-detail thead tr div.indicators-title span {
  font-weight: 300;
}

.indicators-detail tbody tr td:first-child {
  color: #27224e;
}

.badge {
  border-radius: 3px;
  width: fit-content;
  color: white;
  padding: 2px 7px;
  margin-right: 10px;
  height: fit-content;
  vertical-align: middle;
  text-align: center;
}

#page-numbers ul li:first-child,
  #page-numbers ul li:last-child {
    display: none;
  }

#page-numbers {
  padding-top: 16px;
  padding-bottom: 80px;
  width: 95%;
  margin: 0 auto 35px auto;
}

#page-numbers ul {
  list-style: none;
  float: none;
  width: fit-content;
  margin: 0px auto;
  display: block;
  padding: 0;
}

#page-numbers ul li {
  float: left;
  cursor: pointer;
  margin-right: 4px;
}

#page-numbers ul li a {
  border-radius: 4px;
  text-decoration: none;
  font-size: 11px;
  font-family: 'Inter' sans-serif;
}

/* #page-numbers ul li.active a,
#page-numbers ul li.active a:hover {
  background: #27224e;
  color: #fff;
  font-weight: bold;
}

#page-numbers ul li a:hover {
  background: #f5f5f5;
} */

#page-numbers li.title {
  cursor: auto;
}

.navbar-toggler {
  display: none;
}

.menuMobile {
  display: none;
}

.navTabs {
  margin-bottom: 25px;
  border: 0;
}

.navTabs .nav-item {
  margin-right: 30px;
}

.navTabs .nav-item svg {
  font-size: 19px;
  margin-bottom: 2px;
}

.navTabs .nav-item:last-child {
  margin-bottom: 30px;
}

.navTabs .nav-link {
  border: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  color: #c7d0e4;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

.navTabs .nav-link:hover {
  color: #c7d0e4;
  border: 0;
}

.navTabs .nav-link.active {
  background: transparent;
  color: #27224e;
  border-bottom: 3px solid #27224e;
}

/* .accountContainer .isDesktop{
  display: flex;
} */

.accountContainer .user.isDesktop {
  display: block;
}

.accountContainer .isMobile {
  display: none !important;
}

@media (max-width: 768px) {
  .accountContainer .isDesktop {
    display: none !important;
  }

  .accountContainer .user.isDesktop {
    display: none;
  }

  .accountContainer .isMobile {
    display: block !important;
  }

  .accountContainer .slick-arrow {
    display: none !important;
  }

  .accountContainer .slick-slide > div {
    margin: 0 10px;
  }

  .accountContainer .menuItem a {
    padding: 9% 0;
  }

  body,
  #message {
    margin-top: 0;
    box-shadow: none;
  }

  .modelos-container .highcharts-input-group {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .metadataBox {
    font-size: 16px;
  }

  .metadataBox .value {
    font-size: 26px;
  }

  .metadataBox .value span {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .menu {
    width: 100%;
  }

  .feed {
    background: transparent;
  }

  .account {
    display: block;
    width: 100%;
  }

  .feed.indicadores .App-container {
    margin-top: 0px;
  }

  .post-feed {
    width: 90%;
  }

  .accountContainer .menuItem .iconItem {
    margin-bottom: -10px;
  }

  .account .account-form,
  .account .account-info {
    /* float: left; */
    width: 100%;
  }

  .account .account-info .box {
    margin-bottom: 20px;
  }

  .metadataBoxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
  }

  #loadingPage {
    left: 0;
    width: 100%;
  }
}
.menuDesktopWrapper {
  height: calc(100vh - 180px);
  justify-content: space-between;
  display: flex;
  flex-flow: column;
}

.menuDesktop {
  position: relative;
}

.menuDesktop .nav-bottom {
  position: absolute;
  bottom: 0;
}

.menuDesktop .subItens {
  display: none;
}

.menuDesktop .nav-item.active {
  border-left: #3de8a0 5px solid;
  color: #3de8a0;
}

.menuDesktop .nav-item.active svg {
  color: #3de8a0;
}

.openMenu .nav-item.active {
  border-left: 0;
}

.openMenu .nav-item.active svg {
  color: #fff;
}

.openMenu .subItens {
  display: block;
}

.openMenu .nav-link {
  font-size: 15px;
}

.openMenu .nav-link.no-active {
  opacity: 0.5;
  cursor: default;
}

.openMenu .nav-link.no-active svg {
  margin-bottom: 3px;
}

.openMenu .nav-link span {
  font-weight: bold;
}

.openMenu .nav-icon {
  font-size: 0.9em !important;
  margin-bottom: 2px;
}

.openMenu .nav-icon.icon-graf {
  font-size: 1.4em !important;
}

.openMenu .subItens .nav-item.active .nav-link {
  color: #3de8a0;
}

.openMenu .subItens .nav-item {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

.openMenu .subItens .nav-item .nav-link {
  display: block;
  padding: 0.3rem 1rem;
}

.notification-badge-display-block {
  display: none;
}

.notification-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: fixed;
  padding: 12px 0px 12px 12px;
  margin-top: 10px;
  z-index: 11;
  right: 20px;
  border: 1px solid transparent;
  border-left: 4px solid #35e098;
  border-radius: 4px;
  background-color: #203735;
  color: #35e098;
}

.notification-badge .notification-badge-text {
  margin-left: 5px;
  font-family: "Inter", sans-serif;
}

.notification-badge .notification-badge-text .notification-badge-link a {
  font-size: 12px;
  color: #35e098;
  text-decoration: underline;
}

.notification-badge .notification-badge-closeIcon {
  padding-top: 0px;
 }

.notification-badge .notification-badge-closeIcon:hover {
  background-color: transparent;
}

.notification-badge .notification-badge-closeIcon svg {
 font-size: 20px;
 color: #35e098;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width: 768px) {
  .App .d-flex:first-child {
    display: block !important;
  }

  .navTabs .nav-item {
    margin-right: 4px;
  }

  .areaUser {
    margin-top: 1em;
    position: relative;
    height: 20px;
  }

  .post-feed .feed-option .colorFont span.mr-1 {
    color: #fff;
  }

  .areaUser .panelUser {
    margin-right: 0;
    margin-left: 0;
    left: 50%;
    width: 250px;
    align-items: center;
    justify-content: center;
    display: -webkit-inline-box;
    position: absolute;
    top: 64%;
    transform: translate(-50%, -50%);
    height: auto !important;
  }

  .areaUser ul {
    margin-bottom: -1rem;
    padding-left: 0;
  }

  .menu .nav-item {
    float: left;
    width: 100%;
  }

  .menuDesktop,
  .menuDesktopWrapper {
    display: none;
  }

  .menuMobile {
    display: block;
  }

  .menuMobile {
    padding-left: 36px !important;
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    overflow: hidden;
    max-height: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .menu .menu-items .menuMobile .nav-item .progress_pills_container {
    display: none;
  }

  .navbar-toggler {
    display: block;
    float: right;
    margin-top: 25px;
    margin-right: 50px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    filter: brightness(0) invert(1);
    width: 56px;
    height: 40px;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("assets/menu-icons.svg");
  }

  .menu .menu-items .nav {
    float: left;
    width: 100%;
    padding-left: 10px;
  }

  #animeMenu {
    max-height: 504px;
  }

  .menu .menu-items .nav-item .progress_pills_container {
    margin-left: -30px;
  }

  .menu {
    float: left;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100px;
    display: block;
    position: relative;
  }

  .menu-items {
    position: relative;
  }

  .content-body {
    width: 100%;
    min-height: auto;
    margin-left: 0;
    position: relative;
    display: block;
    float: left;
  }

  .menu .brand {
    float: left;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .App .listGraphs .d-flex:first-child {
    display: flex !important;
  }

  .indicadores .App-container .listGraphs .featuredGraph .content-indi {
    background: #ffffff;
  }

  .feed.indicadores {
    margin-bottom: -50px;
  }

  /* .post-feed .feed-option .w-75 svg {
    margin-bottom: 30px;
  } */

  .graficos-table tbody tr td:nth-child(4) p div {
    display: none;
  }

  .feed-post .thumbFeed img {
    height: 413;
  }
}

@media (max-width: 780px) {
  .areaUser .panelUser .btnAssine {
    font-size: 13px;
    width: 115px;
  }

  .areaUser .panelUser .btnLogin {
    font-size: 13px;
    width: 98px;
  }

  .account {
    margin-right: 0;
    margin-top: 0;
    width: 100%;
    padding: 15px !important;
  }

  .account .btnLogout {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    background: #1e1e2b;
    border: 2px solid transparent;
    border-radius: 4px;
    color: #fff;
    font-family: "Inter", sans-serif !important;
    padding: 6px 10px;
    margin-bottom: 15px;
    position: relative;
    margin-top: 15px;
  }

  .account .btnLogout::after {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background: linear-gradient(#ffa562, #f8f24e);
    content: "";
    z-index: -1;
    border-radius: 4px;
  }

  .account .btnPlan {
    background-image: linear-gradient(#ffa562, #f8f24e) !important;
    color: #27224e !important;
    margin-bottom: 15px;
  }

  .account .MuiOutlinedInput-input {
    padding: 0 14px !important;
    height: 41px !important;
    font-size: 16px !important;
  }

  .account .MuiInputBase-root {
    border-radius: 4px;
  }

  /* .accountContainer .head {
    display: block;
    padding-bottom: 85px;
  } */

  .accountContainer .MuiFormControl-root,
  .accountContainer .MuiFormControl-root .w-50 {
    width: 100% !important;
  }

  /* .accountContainer .head .planUser {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
  } */

  .account::after {
    left: 43px;
    background-color: #fff;
    /* content: ""; */
    display: block;
    height: 54%;
    position: absolute;
    width: 88.8%;
    z-index: 1;
  }

  /* .accountContainer .head .planUser .plan {
    background-image: linear-gradient(#ffa562, #f8f24e) !important;
    border: 0px !important;
    color: #27224e;
    padding: 8px 15px;
  } */

  /* .accountContainer .head .imageUser {
    width: 100%;
    text-align: center;
    font-size: 58px;
  } */

  .account-container .textGraph {
    left: 60%;
  }

  /* .accountContainer .circleProgress {
    width: 120px;
    height: 120px;
    margin-top: 10px;
  } */

  /* .accountContainer .circleProgress span {
    left: 45.5%;
    top: 24%;
  } */

  .account .account-info {
    z-index: 9;
    position: relative;
  }

  /* .accountContainer .head .imageUser img:last-child {
    right: 0px;
    bottom: 10px;
  }

  .accountContainer .head .imageUser img:first-child {
    left: 32px;
    top: 20px;
  } */

  .accountContainer .headUser .MuiInputBase-root {
    font-size: 16px;
    font-weight: normal;
    background: #fff;
  }

  .accountContainer
    .headUser
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline {
    border: 0;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 8%);
  }

  .areaUser {
    margin-right: 0;
  }

  .App {
    float: left;
    width: 100%;
  }

  /* .feed-post .thumbFeed {
    display: none;
  } */

  .App-container .feed .feed-post {
    width: 100%;
  }

  .highcharts-exporting-group .highcharts-button {
    display: none;
  }

  .highcharts-exporting-group .highcharts-button.highcharts-contextbutton {
    display: block;
  }

  .graficos-table thead,
  .graficos-table thead tr,
  .graficos-table tbody,
  .graficos-table tbody tr {
    width: 100%;
    /* float: left; */
  }

  .graficos-table thead tr th,
  .graficos-table tbody tr td {
    text-align: center;
  }

  .graficos-table thead tr th,
  .graficos-table tbody tr td {
    /* float: left; */
  }

  .graficos-table tbody tr td:nth-child(4),
  .graficos-table thead tr th:nth-child(4) {
    /* width: 55%; */
  }

  .graficos-table tbody tr td:nth-child(3),
  .graficos-table thead tr th:nth-child(3) {
    /* width: 25%; */
  }

  .graficos-table tbody tr td:nth-child(2),
  .graficos-table thead tr th:nth-child(2) {
    width: 10%;
  }

  .App-container .feed .feed-post .colorFont {
    position: absolute;
    top: 25px;
    right: 15px;
  }

  .App-container .feed .feed-post .colorFont span.mr-1 {
    display: none;
  }

  .App-container .feed .feed-post {
    position: relative;
  }

  .areaUser .panelUser {
    margin-right: 0;
    margin-left: 0;
    left: 50%;
    width: 235px;
    align-items: center;
    justify-content: center;
    display: -webkit-inline-box;
    position: absolute;
    top: 64%;
    transform: translate(-50%, -50%);
    height: auto !important;
  }

  .post-feed .feed-option .colorFont div.text-center.mt-2 {
    position: absolute;
    top: -6px;
    right: -60%;
  }

  .post-feed .feed-option h4 {
    font-size: 32px;
  }

  .post-feed {
    margin-right: 14px;
    /* background: #fff; */
  }

  .App .listGraphs .d-flex:first-child {
    display: block !important;
    padding-left: 25px;
    max-width: 300px !important;
  }

  .feed-post .d-flex {
    /* display: block !important; */
    float: left;
    width: 100%;
  }

  .feed-post {
    float: left;
    /* padding: 30px; */
    /* width: 100%; */
  }

  .feed-post .colorFont {
    position: relative;
    min-height: 30px;
    margin-top: 10px;
  }

  #page-numbers {
    width: 100%;
    padding-bottom: 0px;
    float: left;
  }

  /* #page-numbers ul li:first-child,
  #page-numbers ul li:last-child {
    display: none;
  } */

  .feed-option p {
    font-size: 24px !important;
  }

  /* .post-feed .feed-option {
    padding: 2em 2.5em 2.7em;
    display: block !important;
  } */

  .post-feed .feed-option {
    padding: 0 16px;
    width: 100%;
  }

  .postContent h2 {
    font-size: 18px;
  }

  .post-feed .post {
    width: 100%;
    padding: 0 16px;
    margin: 3em 0;
  }

  .post-feed .feed-option .w-75 {
    width: 100% !important;
  }

  .post-feed .feed-option .colorFont {
    position: relative;
    float: left;
  }

  .post .post-info-container {
    flex-direction: column;
  }

  .post .post-info-container .post-author {
    margin-bottom: 20px;
    padding: 0;
  }

  .post .post-info-container .icons-social-media-content {
    align-items: flex-start;
    /* gap: 15px; */
    padding: 10px 0;
  }

  .post .post-info-container .icons-social-media-content .social-media {
    gap: 4px;
  }

  .progress-bar {
    padding: 25px 16px;
  }

  .progress-bar .MuiLinearProgress-root {
    width: 100%;
  }

  .graficos-table,
  .fundamentos-table {
    width: 100%;
    font-size: 14px;
  }

  .graficos-table thead th:last-child,
  .graficos-table tbody tr td:last-child,
  .graficos-table thead th:first-child,
  .graficos-table tbody tr td:first-child {
    display: none;
  }

  /* #loadingPage .lds-grid {
    left: 43%;
  } */

  .pointProgress.lds-grid {
    top: 43%;
  }

  /* .metadataBoxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
  } */

  .footer-detail {
    width: 100%;
    padding: 0 6px;
  }

  .footer-detail .feed .feed-post {
    width: 100%;
  }
}

@media (max-width: 600px) {
  body,
  #message {
    margin-top: 0;
    background: white;
    box-shadow: none;
  }

  /* .accountContainer .circleProgress span {
    left: 42%;
  } */

  .account .account-info {
    margin-right: 0;
  }

  .account::after {
    height: 100%;
    left: 21.2px;
  }

  .footer-detail .footer-detail-veja-tambem {
    display: block !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #becff1;
  }

  .footer-detail 
  .footer-detail-container 
  .footer-detail-prev {
    display: none;
  }

  .footer-detail 
  .footer-detail-container 
  .footer-detail-next {
    display: none;
  }

  .footer-detail .feed {
    flex-direction: column !important;
  }
}

@media (max-width: 425px) {
  .footer-detail .footer-detail-anterior-proximo {
    display: none !important;
  }

  /* .feed{
    flex-direction: column !important;
  } */

  .feed-post {
    margin: 0;
    margin-bottom: 20px;
  }

  .feed-post .thumbFeed img {
    height: 227px;
  }

  .account-container .textGraph {
    left: 67%;
  }

  .account-container .textGraph:hover .bodyGraph {
    left: -50px;
  }

  .notification-badge {
    left: 24px;
    right: 20px;
  }
}

@media (max-width: 375px) {
  .feed-post .thumbFeed img {
    height: 200px;
  }
}

@media (max-width: 320px) {
  .graficos-table tbody tr td:nth-child(3),
  .graficos-table thead tr th:nth-child(3) {
    display: none;
  }

  .graficos-table tbody tr:nth-child(odd) td:nth-child(4) {
    background: #f2f4fc;
  }

  .graficos-table tbody tr:nth-child(even) td:nth-child(4) {
    background: #fafbff;
  }

  .graficos-table tbody tr td:nth-child(2),
  .graficos-table thead tr th:nth-child(2) {
    /* width: 45%; */
  }

  .graficos-table tbody tr td p {
    position: relative;
    float: left !important;
    display: block !important;
    width: 100% !important;
    transform: initial !important;
    top: 25px !important;
    left: 0 !important;
    margin-bottom: 0 !important;
    text-align: center !important;
  }

  .areaUser {
    margin-right: 0;
  }

  .areaUser .panelUser .btnAssine,
  .areaUser .panelUser .btnLogin {
    font-size: 13px;
  }

  .areaUser .panelUser {
    width: 240px;
  }

  .Site.login .App-container .box-options .nav-tabs .nav-link {
    font-size: 16px;
  }

  .Site.login .App-container .box-options .nav-tabs .nav-link:first-child {
    margin-left: 6%;
  }

  .feed-post .thumbFeed img {
    height: 170px;
  }

  .accountContainer .textGraph {
    left: 70%;
  }
}

.graficos-table .tooltip-icon:hover path {
  fill: #3de8a0;
  font-size: 12px;
  font-weight: lighter;
  line-height: 16px;
}

.tooltip-body .tooltip-inner {
  font-family: "Inter", sans-serif;
  background: #3de8a0;
  font-size: 12px;
  font-weight: lighter;
  line-height: 16px;
  text-align: left;
  color: #000;
}

.tooltip-body .arrow:before {
  border-bottom-color: #3de8a0;
}

.content-text iframe {
  width: 100%;
  min-height: 500px;
}

.highcharts-graph.zone-green {
  stroke: rgb(95, 227, 161);
}

.highcharts-graph.zone-blue {
  stroke: rgb(86, 217, 254);
}

.highcharts-graph.zone-yellow {
  stroke: rgb(253, 229, 94);
}

.highcharts-graph.zone-orange {
  stroke: rgb(252, 159, 53);
}

.highcharts-graph.zone-red {
  stroke: rgb(247, 52, 138);
}

.highcharts-point {
  stroke: none;
}

.highcharts-color-0 {
  fill: #a3a1fb;
  stroke: #a3a1fb;
}
.highcharts-color-0 .highcharts-area {
  fill-opacity: 1;
  fill: url(#gradient-0);
}

#gradient-1 stop {
  stop-color: #f2a900;
}
#gradient-1 stop[offset="0"] {
  stop-opacity: 0.75;
}
#gradient-1 stop[offset="1"] {
  stop-opacity: 0;
}

.highcharts-color-1 {
  fill: #f2a900;
  stroke: #f2a900;
}
.highcharts-color-1 .highcharts-area {
  fill-opacity: 1;
  fill: url(#gradient-1);
}

#gradient-2 stop {
  stop-color: #5fe3a1;
}
#gradient-2 stop[offset="0"] {
  stop-opacity: 0.75;
}
#gradient-2 stop[offset="1"] {
  stop-opacity: 0;
}

.highcharts-color-2 {
  fill: #5fe3a1;
  stroke: #5fe3a1;
}
.highcharts-color-2 .highcharts-area {
  fill-opacity: 1;
  fill: url(#gradient-2);
}

.highcharts-menu {
  background: #f3f4fd;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: rgba(0, 4, 77, 0.1) 3px 3px 20px;
  border: none;
  margin: 5px 0;
  /* border: solid 1px #d9dbe8; */
}

.highcharts-button-pressed,
.highcharts-button-hover {
  background-color: #cad1ef;
}

.modelInputContainer {
  background: linear-gradient(to right, #679cf6, #4072ee);
  box-shadow: rgba(13, 40, 197, 0.4) 0px 3px 10px;
  color: white;
  padding: 1.5rem;
  border-radius: 0.2rem;
  border: 0;
}

.gauge div {
  width: auto !important;
}

.indicators-detail table {
  margin-bottom: 30px;
}

.indicators-detail h6 {
  color: #27224e;
  font-size: 18px;
  text-align: left;
  margin: 24px 12px 12px;
}

fieldset {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.indicators-detail thead {
  background: #2b2f46 !important;
  color: #27224e;
  font-size: 12px;
  text-align: left;
}

.indicators-detail thead > tr {
  background: transparent !important;
}

.indicators-detail tbody {
  color: #898989;
  font-size: 16px;
  text-align: left;
}

.indicators-detail tbody tr td {
  position: initial !important;
  width: initial !important;
  height: 48px;
}

@media (min-width: 2500px) {
  .accountContainer .menuItem {
    padding: 12px 5% !important;
  }
}

@media (max-width: 280px) {
  .section-paradigma-tracks .card-track {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}

/* Custom Style */

.d-flex {
  display: flex !important;
}

.justify-content-between {
  width: 100%;
  justify-content: space-between !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}

/* .w-75 {
  width: 75% !important;
} */

.align-items-center {
  align-items: center !important;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

h6 {
  margin-top: 0;
  margin-bottom: 5px;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.text-center {
  text-align: center !important;
}