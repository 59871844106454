.additional-details-crypto{
  margin-bottom: 10px;

  .fourpf{
    .input-group{
      border: none !important;
    }

    .fourpf2{
      border: 1px solid #e2e2e2 !important;
    }
  
    .mask-input-style{
      width: 100%;
      outline: none;
      border: 1px solid #e2e2e2 !important;
      border-radius: 3px;
    }
  }
}



.paywallUser {
  position: relative;

  .noUser-feed {
    filter: blur(1px);
    -webkit-filter: blur(1px);
    min-height: 900px;

    &:after {
      content: '';
      background: linear-gradient(rgba(255, 255, 255, 0), #f3f4fd 25%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.no-margin-top-paywall {
  .content-paywall {
    margin-top: 0% !important;
  }
}

.margin-top-paywall-15 {
  .content-paywall {
    margin-top: 15% !important;
  }
}

.parcel-select {
  padding: 8px 12px;
  width: 100%;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: #212121;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  outline: none;
}

.content-paywall {
  // position: absolute;
  // margin-top: 50%;
  margin-top: 50px;
  width: 100%;
  text-align: center;

  .item-plan {
    display: inline-block;

    &.item-planPro {
      .pay-content {
        padding-top: 40px;
      }
    }

    .lockItem {
      margin-right: 20px;
    }

    .pay-content {
      padding: 40px 20px;
      width: 250px;
      border-radius: 4px;
      // margin-right: 20px;
      box-shadow: 0 3px 15px 4px rgba(45, 52, 193, 0.2);
      background: #ffffff;

      img {
        width: 51px;
      }

      hr {
        background: #27224e;
        height: 5px;
        border-radius: 96px;
        width: 100%;
        margin-bottom: 5px;
        margin-top: -5px;
      }

      .firstIntro {
        width: 100%;
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
        color: #27224e;
        opacity: 0.65;
        margin-bottom: 15px;
        font-family: "Inter", sans-serif;
      }

      .title {
        color: #27224e;
        font-family: "Inter", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        padding-top: 10px;
        margin: 0;
      }

      .subtitle {
        color: #27224e;
        font-family: "Inter", sans-serif;
        letter-spacing: 1px;
        font-size: 14px;
        padding: 0 20px;
      }

      a {
        max-width: 232px;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.3;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        color: #6b6978;
        line-height: 1.2;
      }

      button {
        width: 100%;
        font-family: "Inter", sans-serif;
        align-items: center;
        background-color: #3de8a0;
        border-radius: 4px;
        color: #126c46;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        height: 42px;
        justify-content: center;
        padding: 13px 32px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color .25s cubic-bezier(.165,.84,.44,1);

        a {
          font-weight: 600;
          font-size: 16px;
          color: #fff;
          opacity: 1;
        }
      }
      .total-price {
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
      }

      .price {
        margin-top: 0;
        font-size: 11px;
        color: #6b6978;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        // text-shadow: 1px 1px #3de8a0;
      }
    }

    &.item-planPro {
      .content-body {
        box-shadow: 3px 3px 20px 0px #adadad;
        margin-right: 30px;

        button {
          &:hover {
            background: #fff;
            color: #27224e;
          }
        }
      }
    }

    &.item-planLight {
      .content-body {
        padding-top: 46px;

        button {
          background: #fff;
          color: #27224e;

          &:hover {
            background: #3de8a0;
            color: #fff;
          }
        }
      }
    }
  }
}

.modalPresent {
    z-index: 9999 !important;

    .MuiDialog-container {
      height: 100% !important;
      background-color: rgba(0,0,0,.6);
    }

    .MuiDialog-paper {
      margin: 0;
      overflow-y: initial;
    }

    .MuiDialog-paperWidthSm{
      // max-width: 550px;
      width: 432px;
    }

    .modal-buttonClose{
      position: absolute;
      right: 0px;
      top: 0px;
      margin: 16px;
      cursor: pointer;
    }

    .btn-prev {
      width: 24px;
      position: absolute;
      top: -24px;
      left: 0px;
      cursor: pointer;
    }

    .message-coupon {
      align-items: center;
      border-radius: 4px;
      display: flex;
      height: 46px;
      justify-content: flex-start;
      margin: 10px 0;
      min-width: 105px;
      padding: 8px 12px;
      width: 100%;

      span {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .message-cardPayment {
      margin: 0px;
    }

    .MuiDialogContent-root {
      border: 0;
      border-radius: 4px;
      box-shadow: 3px 3px 20px rgba(45, 52, 193, 0.1);
      font-family: "Inter", sans-serif;
      padding: 16px !important;
      overflow-y: clip;

      .contentCourse {
        width: 100%;
        margin: 0px auto;
      }

      .descCourse {
        margin: 0 16px 16px;

        .annualToggler {
          display: flex;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 20px;

          div.react-toggle {
            margin: 0 8px;

            .react-toggle-track-x,
            .react-toggle-track-check {
              display: none !important;
            }

            .react-toggle-thumb {
              background: #fff;
              border-color: #dadee2 !important;
              // top: -2px;
              height: 18px;
              width: 18px;
              box-shadow: none;
            }

            .react-toggle-track {
              width: 47px;
              height: 20px;
              border-radius: 20px;
              background: #dadee2;
            }

          }

          h5 {
            // text-transform: uppercase;
            font-weight: 400;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin: 0;
            color: #6b6978;

            &.active {
              color: #27224e;
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              background-image: url(../assets/bg-degrade.png);
              border-radius: 4px;
              color: #fff;
              height: 23px;
              width: 83px;
              font-size: 10px;
              margin-left: 8px;
            }
          }
        }

        .content-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .title {
            font-size: 1.8rem;
            font-weight: 600;
            color: #27224e;
            margin-bottom: 0;
            margin-top: 0;
          }

          .select-modals {
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 600;
            color: #27224e;
            border: none;
            outline: none;
          }

          .content-price {
            margin-bottom: 0px;
            .total-price {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #27224e;
            }
            .price {
              color: #6b6978;
              font-size: 11px;
              font-weight: 400;
              margin-bottom: 0px;
              margin-top: 0;
    
              span {
                font-size: 20px;
                font-weight: normal;
              }
            }
          }
        }

        .divisor {
          margin-bottom: 16px;
          background-color: #dcdce3;
          height: 1px;
        }

        .content-itensCourse {
          display: flex;
          justify-content: space-between;
          .title-itensCourse {
            margin: 0;
            font-size: 9px;
            font-weight: 600;
            text-transform: uppercase;
            color: #6b6978;
          }
  
          .itensCourse {
            list-style: none;
            color: #6b6978;
            padding-left: 0;
            padding-bottom: 20px;
            font-size: 13px;
            li {
              display: flex;
              margin-bottom: 8px;
              img {
                object-fit: contain;
              }
              div {
                margin-left: 8px;
              }
            }
          }
        }

        
      }

      .imgPlan {
        float: right;
        margin-right: 20px;
        max-width: 140px;
      }

      .customInputEmail {
        width: 100%;
        margin: 0px auto 15px;
        // height: 42px;
        // box-shadow: 2px 2px 6px rgba(46, 53, 194, 0.2);
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        display: flex;

        &.MuiInput-underline:before, &.MuiInput-underline:after{
          border: 0 !important;
        }

        .MuiInputAdornment-root {
          background: #fff;
          border: 0;
          font-size: 25px;
          color: #212121;
          padding-left: 20px;
        }

        input {
          border: 0;
          color: #212121;
          font-size: 14px;
          // font-weight: bold;
          outline: none;
          box-shadow: none;
          // height: 42px;
          padding: 13px;
          padding-left: 8px;

          &::placeholder {
            color: #212121;
            // opacity: 1;
          }

          &:-ms-input-placeholder {
            color: #212121;
          }

          &::-ms-input-placeholder {
            color: #212121;
          }
        }
      }

      .checkbox-terms-of-use {
        display: flex;
        align-items: center;
        padding-bottom: 15px;

        .checkbox {
          margin: 0;
          height: 16px;
          width: 16px;
        }

        span {
          color: #969696;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          padding-left: 8px;

          a {
            color: #1cc27d;
            margin: 0 2px;
            text-decoration: none;
          }
        }
      }

      .btn {
        font-family: "Inter", sans-serif;
      }

      .btn-payCripto {
        margin-bottom: 10px;
        background: #0564cf;
        border-color: #0564cf;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        text-align: center;
        width: 90%;
        padding: 10px;
        box-shadow: 0px 3px 6px rgba(45, 52, 193, 0.2);
      }

      .btn-payCard {
        margin-bottom: 10px;
        background: #3de8a0;
        border-color: transparent;
        border-radius: 4px;
        color: #126c46;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
        width: 100%;
        padding: 16px;
        // box-shadow: 0px 3px 6px rgba(45, 52, 193, 0.2);
      }

      .btn-payCard:hover {
        background-color: #68ffbf;
        opacity: 1;
      }

      .btn-coupon {
        // margin-bottom: 10px;
        background: transparent;
        border: 1px solid transparent;
        color: #126c46;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        text-align: center;
        width: 100%;
        padding: 13px 32px;
        // box-shadow: 0px 3px 6px rgba(45, 52, 193, 0.2);
        border-radius: 3px;
        cursor: pointer;
      }

      .btn-form-coupon {
        // margin-bottom: 10px;
        background: #3de8a0;
        border-color: transparent;
        border-radius: 4px;
        color: #126c46;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
        width: 100%;
        padding: 16px;
      }
      .data-coupon {
        text-align: center;
        width: 100%;
        height: 100%;
        margin: 24px auto 0;

        .valid-header {
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
        }

        .valid-text-header {
          font-family: "Inter", sans-serif;
          font-size: 50px;
          font-weight: 700;
          line-height: 60px;
          color: #27224e;
          max-width: 542px;
          margin: 0px 40px;
        }

        img {
          margin-bottom: 20px;
        }
      }


      .btnPlan {
        // width: 100%;
        margin: 13px 32px;
        display: block;
        font-size: 11px;
        font-weight: 400;
        color: #6b6978;
        text-decoration: underline;
      }

      .data-creditCard {
        font-family: "Inter", sans-serif;
        text-align: center;
        width: 100%;
        margin: 24px auto 0;

        .form-wrapper-scroll {
          overflow-y: auto;
          overflow-x: hidden;
          width: auto;
          max-height: 440px;
          height: auto;
          padding-top: 12px;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        label {
          color: rgba(0, 0, 0, 0.54);
          font-family: "Inter", sans-serif;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 0.00938em;
          font-size: 1rem;
        }

        .flex-modal {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }

        .flex-modal-center {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .margin-modal-divisor {
          margin: 24px 0px;

          .text-modal-divisor {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            color: #6b6978;
            white-space: nowrap;
          }

          .modal-divisor {
            background-color: #e0e0e0;
            height: 1px;
            width: 229px;
          }
        }

        .margin-input-group {
          padding-right: 16px;
        }

        .padding-input-group {
          padding-bottom: 12px !important;
        }

        .input-group {
          width: 100%;
          padding-bottom: 16px;

          .label-input-group {
            display: block;
            padding-bottom: 8px;
            text-align: left;
            width: 100%;
            color: #27224e;
            font-family: "Inter", sans-serif;
            font-size: 13px;
            font-weight: 400;
          }

          #documentNumber:focus {
            border-color: #e0e0e0;
            outline: 0;
          }
        }

        .text-field {
          width: 100%;
          padding: 8px 12px;
          height: 42px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          color: #212121;
          font-family: "Inter", sans-serif;
          font-weight: 400;
          outline: 0;
        }

        .btn-block {
          border-color: #3de8a0;
          background: #3de8a0;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          // margin-bottom: 20px;
          color: #126c46;
          // margin-top: 35px;
          box-shadow: none;
          position: relative;
          width: 100%;
          padding: 16px;
          border: 0;
          border-radius: 3px;
          cursor: pointer;
        }

        .btn-block:hover {
          background-color: #68ffbf;
          opacity: 1;
        }

        .progress {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }

        .btn-prev {
          width: 24px;
          position: absolute;
          top: -24px;
          left: 0px;
          cursor: pointer;
        }

        img {
          width: 106px;

          &.imgPayments {
            width: auto;
          }
        }

        form {
          .expiration {
            margin-right: 16px;
          }
        }
      }

      .resultPayment {
        width: 85%;
        margin: 0px auto;
        height: 150px;
        margin-bottom: -260px;

        .descPayment {
          float: left;
          text-align: left;

          h4 {
            font-size: 34px;
            color: #27224e;
            font-weight: bold;
          }

          h5 {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            color: #3de8a0;
            margin-top: 10px;
            margin-bottom: 0;
          }

          p {
            font-size: 14px;
            color: #adadad;
            text-decoration: underline;
          }

          &.payFalse {
            h5 {
              color: #f26e8d;
            }
          }

          .tags {
            float: left;
            width: 100%;

            p {
              float: left;
              margin: 0;
              font-size: 12px;
              text-decoration: none;
              text-transform: uppercase;
              font-weight: bold;
              border-radius: 4px;
              color: #fff;
              padding: 4px 8px 3px;
              letter-spacing: 0.5px;

              &.tagFree {
                background: #3de8a0;
                border: 2px solid #3de8a0;
                color: #fff;
                padding: 3px 8px 2px;
                margin-bottom: 8px;
                margin-right: 10px;
              }

              &.tagPrice {
                background: transparent;
                border: 2px solid #3de8a0;
                color: #3de8a0;
                padding: 3px 8px 2px;
                margin-bottom: 8px;
                margin-right: 10px;
              }

              &.tagClass {
                background: #7e95fc;
              }
            }
          }
        }

        img {
          float: right;
          width: 170px;
        }
      }

      #form-coupon{
        .label-form-coupon {
          text-align: left;
          margin-bottom: 8px;
          font-size: 13px;
          font-weight: 400;
        }

        .MuiInput-underline:before, .MuiInput-underline:after{
          border: 0;
        }
        
        .MuiFormControl-root{
          width: 100%;

          .MuiInputBase-root{
            width: 100%;
            height: 48px;
            border: 1px solid #e0e0e0;
            // box-shadow: 2px 2px 6px #2e35c233;
            margin: 0 auto 15px;
            border-radius: 4px;
  
            input{
              border: 0;
              color: #212121;
              font-size: 14px;
              font-weight: 400;
              outline: none;
              box-shadow: none;
              height: 48px;
              padding-left: 8px;
            }
          }
        }
      }

      .data-cripto {
        margin-top: -48px;
        margin-left: -16px;
        margin-right: -16px;

        .headerCripto {
          background: #1f2a36;
          color: #fff;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 30px 15px;

          .image {
            float: left;
            background: #fff;
            border-radius: 10px;
            padding: 12px 5px;
            margin-right: 20px;

            img {
              width: 60px;
            }
          }

          .title {
            font-size: 24px;
            font-weight: bold;
          }

          .subtitle {
            color: #adadad;
            font-size: 19px;
          }
        }

        .description {
          padding: 20px;
          color: #5f676e;
        }

        form {
          padding: 0 20px;

          .input-group {
            width: 100%;
            box-shadow: none;
            border: 1px solid #e2e2e2;
            height: 50px;

            input {
              font-size: 16px;
              color: #212529;
              font-weight: normal;
            }

            .input-group-append {
              span {
                background: transparent;
                border: 0;
                cursor: pointer;

                svg {
                  font-size: 20px;
                  color: #8d9298;
                }

                &.inputPrice {
                  color: #0760ce;
                }
              }
            }
          }

          .timePay {
            img {
              margin-top: -4px;
              margin-right: 5px;
            }

            span {
              &:last-child {
                color: #90959b;
              }
            }
          }

          .imageQR {
            text-align: center;
          }

          .btn-qrcode {
            width: 100%;
            margin-top: 20px;
            background: #fff;
            color: #1770d3;
            border-color: #1770d3;
            font-weight: 600;
            padding: 12px;

            svg {
              font-size: 22px;
              margin-top: -4px;
              margin-right: 6px;
            }
          }



          label {
            margin-bottom: 5px;
          }
        }

        .btn-prevCripto {
          background: #fff;
          border: 0;
          color: #1d2834;
          font-size: 30px;
          float: left;
          margin-top: 15px;
        }
      }
    }

    button.close {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 60px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background: #fff;
      opacity: 1;
      padding: 0px 9px 4px 9px;
      color: #27224e;
      font-size: 36px;
      font-weight: bold;
      z-index: 999;
    }

  &.modalPayment {
    .modal-dialog {
      .modal-content {
        .descCourse {
          .price {
            color: #77d1ab;
            font-size: 17px;
            font-weight: normal;
            text-shadow: none;
            margin-bottom: 20px;

            span {
              font-size: 24px;
              font-weight: bold;
            }
          }
        }

        .imgPlan {
          right: 50px;
          width: 160px;
          position: absolute;
        }

        .resultPayment {
          margin-bottom: -210px;

          .descPayment {
            &.payFalse {
              .tags {
                p {
                  &.tagPrice {
                    border: 2px solid #f26e8d;
                    color: #f26e8d;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.modalFree {
    .modal-dialog {
      .modal-content {
        .resultPayment {
          margin-bottom: 0px;
        }
      }
    }
  }

  &.modalVote {
    .modal-dialog {
      .modal-content {
        .imgPlan {
          margin-bottom: 20px;
        }

        .tags {
          float: left;
          width: 100%;

          p {
            float: left;
            margin: 0;
            font-size: 12px;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 4px;
            color: #fff;
            padding: 4px 8px 3px;
            letter-spacing: 0.5px;

            &.tagClass {
              background: #becef4;
              margin-top: 10px;
            }
          }
        }

        .text-center {
          margin-bottom: -90px;

          &.resultPayment {
            margin-bottom: -220px;
          }
        }
      }
    }
  }
}

.modalVideoTutorial {
  .MuiDialog-paperWidthSm{
    width: 600px;
  }

  .button-skip {
    float: right;
    padding: 8px 10px;
    background-color: #fff;
    border: 1px solid #27224e;
    color: #27224e;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
  }

}

@media (max-width: 768px) {
  .modalPresent {
    .data-creditCard {
      .card-payment-scroll {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 370px;
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  body {
    .modalPresent {
      .descCourse {
        .annualToggler {
          h5 { 
            span {
              width: 50px !important;
            }
          }
        }  
        .content-title {
          .title {
            font-size: 1.4rem !important;
          }

          .select-modals {
            font-size: 1.2rem !important;
          }
        }
      }
      .modal-dialog {
        button.close {
          right: 15px;
        }

        .modal-content {
          .imgPlan {
            display: none;
          }

          .data-creditCard {
            form {
              .state,
              .city,
              .zip,
              .expiration,
              .cvc {
                width: 100%;
              }
            }
          }

          .resultPayment {
            width: 95%;
            margin-bottom: -140px;

            img {
              float: none;
            }

            .descPayment {
              float: none;
              text-align: center;

              h4 {
                font-size: 30px;
              }

              .tags {
                margin-top: 20px;
                margin-bottom: 20px;

                p {
                  float: none;
                  width: auto;
                  display: -webkit-inline-box;
                }
              }
            }
          }
        }
      }

      &.modalPayment {
        .modal-dialog {
          .modal-content {
            .resultPayment {
              margin-bottom: -40px;
            }
          }
        }
      }

      &.modalFree {
        .modal-dialog {
          .modal-content {
            .resultPayment {
              margin-bottom: 0;
              height: 330px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .modalPresent {
    .MuiDialog-paperWidthSm{
      width: 100%;
    }
    .MuiFormLabel-root {
      font-size: .8rem !important;
    }
    // .card-email-scroll {
    //   position: relative;
    //   width: 100%;
    //   height: 500px;
    //   overflow-y: auto;
    // }
  }
}


.modal3,
.modal4 {
  text-align: center;
}

.modal3 h2,
.modal4 h2 {
  font-family: "Inter", sans-serif;
  font-size: 45px;
  font-weight: 700;
  color: #27224e;
  padding: 15% 0 2%;
}

.modal3 h2 span,
.modal4 h2 span {
  color: #f27592;
}

.modal3 h5,
.modal4 h5 {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #27224e;
  padding: 7% 0 10%;
}

.modal3 h5 a,
.modal4 h5 a {
  color: #3de8a0;
  text-decoration: underline;
}

.modal3 p,
.modal4 p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #27224e;
  padding: 0;
}