body.dark-mode {
  background: #1e1e2f !important;

  .collections-file {
    .highcharts-axis-title {
      color: #fff !important;
      fill:  #fff !important;
    }
  }

  .vernon-color {
    color: #fff;
}

  .drop-in-active {
    color: #666;
  }

  .drop-active {
    color: #fff;
  }

  .collection-dropdown {
    .colletion-dropdown-upper {
      background-color: #24293b;
      color: #fff;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .colletion-dropdown-lower{
      background-color: #24293b;
      color: #fff;
    }
  }

  .colletion-dropdown-lower-search-container {
    background-color: #24293b !important;
    border-color: #fff !important;
    color: #fff !important;

    .ui.input>input {
      background-color: #24293b !important;
      border-color: #fff !important;
      color: #fff !important;
    }
  }

  .login {
    .App-container {
      .box-options {
        .MuiTabPanel-root {
          .title,
          .subtitle,
          label{
            color: #fff;
          }
        }
      }
    }
  }

  .menuFeed {
    background: #1e1e30;
  }

  #loadingPage {
    background-color: #1e1e2f !important;
  }

  .lds-grid div {
    background: #fff;
  }

  .menuToggle {
    .linkAccount {
      color: #fff;

      &:hover {
        color: #3de8a0;
      }
    }
  }

  .graficos-container{
    background-color: #1e1e2f;
  }

  .contentIframe {
    .headMenu {
      float: left;
      width: 95%;
      margin-bottom: 40px;
      margin-top: 20px;
    }
  }

  .metadataBox {
    .value {
      color: #ababaf;
    }
  }
  .seeAlso {
    color: #fff;
  }

  .boxInfo {
    color: #fff;
    .textGraph {
      svg {
        color: #ababaf;
      }

      &:hover {
        svg {
          color: #3de8a0;
        }
      }
    }
  }

  .metabox-indicator {
    background: #1e1e2f;

    .metadataBox {
      background: #26293b;
      border: 1px solid #313452 !important;

      .title {
        color: #fff;
      }

      table {
        th {
          color: #fff;
        }
      }
    }
  }

  .menu {
    background: #27293d;
  }

  .navTabs {
    .nav-link {
      color: #becef4;

      &.active {
        color: #3de8a0;
        border-bottom: 3px solid #3de8a0;
      }
    }
  }

  .indicadores-main-container {
    .indicators-table {
      color: #fff;
      border: 1px solid #313452;

      tr {
        td {
          border-top: 1px solid #313452;

          a {
            color: #fff;
          }
        }

        &:nth-child(odd) {
          background: #26293b;
        }
      }
    }
  }

  .indicadores {
    &.feed {
      border: 0px;
      background: transparent;
      border-radius: 0;
    }

    .App-container {
      .listGraphs {
        .tableIndicators {
          .item {
            background: transparent;
            padding: 1.5em 1.5em 0.5em 0em;
            box-shadow: none;
            margin-bottom: 0;

            .indicators-table {
              color: #fff;
              border: 1px solid #313452;

              .selectTable{
                &.MuiInputBase-root{
                  color: #fff;
                }

                svg{
                  color: #fff;
                }
              }

              .topTable {
                td {
                  border-top: 1px solid #1e1e30 !important;
                  border-left: 1px solid #1e1e30 !important;
                  border-right: 1px solid #1e1e30 !important;
                }
              }

              tr {
                td {
                  border-top: 1px solid #313452;

                  a {
                    color: #fff;
                  }
                }

                &:nth-child(odd) {
                  background: #26293b;
                }
              }
            }
          }
        }
        .featuredGraph {
          .content-indi {
            background: #1d1e31;

            .graph {
              background: #272a3b;

              .highcharts-button {
                fill: #1e1e2f;

                text {
                  fill: #ababaf;
                }
              }

              .openIndicator {
                a {
                  color: #ababaf;
                }
              }

              .title {
                color: #fff;

                svg {
                  color: #ababaf;
                }
              }

              .highcharts-background {
                fill: #272a3b !important;
              }

              .highcharts-legend-item > text {
                fill: #fff !important;
              }
            }
          }
        }
      }
    }
  }

  .account-container{
    .head{
      color: #fff;

      .planUser{
        .plan{
          border: 1px solid #fff;
        }
      }
    }

    .textGraph{
      svg{
        color: #ababaf;
      }

      &:hover{
        .bodyGraph{
          background: #3de8a0;
          color: #000;
        }
      }
    }
  }

  .account {
    // background-image: url("assets/bgAccount@2x.png");
    // background-position: top center;
    // background-size: cover;
    // background-repeat: no-repeat;

    .btnContact{
      color: #fff;
      background: transparent;
      border: 2px solid #fff;

      &:hover{
        background: #fff;
        color: #26293B;
      }
    }

    .MuiInputBase-root{
      background: #363652;
    }

    .border-bottom {
      border-bottom: 1px solid #313452 !important;
    }

    .border-top {
      border-top: 1px solid #313452 !important;
    }

    .MuiFormLabel-root {
      color: #adadad;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #313452;
    }

    .account-info {
      .box {
        background: transparent;
        border: 0;
        color: #fff;

        &.contact {
          .btn {
            color: #27224e;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
          }
        }
      }
    }

    .account-name,
    .text-dark,
    .MuiInputBase-input {
      padding: 0 14px;
      height: 41px;
      color: #9a9aa8 !important;
      font-weight: bold;
      font-size: 18px;

      &.Mui-disabled {
        color: #909095 !important;
      }
    }

    .account-form {
      background: transparent;
      border: 0;
      color: #fff;

      .plan {
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }

  .video-videos-container-item{
    h4 {
      color: #fff !important;
    }
  }

  .feed {
    border: 1px solid transparent;
    background: transparent;
    border-radius: 4px;

    .alertBitcoin,
    .headerFeed {
      background: #27293d;
    }

    // .feed-post {
    //   &:nth-of-type(odd) {
    //     background: #2b2f46;
    //   }
    // }
  }

  .post-feed {
    // border: 1px solid #313452 !important;
    // background: #2b2f46;
    // background: #27293D;
    // border-radius: 4px;
    color: #fff;
    width: 100%;

    .postContent {
      color: #fff;
    }

    // .feed-option {
    //   background: #27293d;
    // }
  }

  #page-numbers {
    ul {
      li {
        a {
          padding: 8px 11px;
          color: #becff1;
          border: solid 1px #303355;

          // &:hover {
          //   background: #4ee8a0;
          //   color: #262a3e;
          // }
        }

        &.disabled {
          a {
            opacity: 0.32;
          }
        }

        &.active {
          a {
            opacity: 0.32;
          }
        }
      }
    }
  }

  .table-striped {
    tr:nth-of-type(odd){
      background-color: #2b2f46 !important;
    }

    tr:nth-of-type(even){
      background-color: #26293d !important;
    }
  }


  .graficos-table,
  .fundamentos-table {
    // border: 1px solid #313452;
    // border-radius: 4px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    thead {
      color: #fff;
      background: #27293d;

      th {
        border-bottom: 0px;
      }
    }

    tr {
      &:nth-of-type(odd) {
        td {
          background-color: #2b2f46 !important;
        }
      }
      &:nth-of-type(even) {
        td {
          background: #26293d !important;
        }
      }
    }
  }

  .fundamentos-container{
    background: #1e1e2f;
  }

  .fundamentos-container,
  .graficos-container {
    .feed-post {
      &:nth-of-type(odd) {
        background: #2b2f46;
      }
      &:nth-of-type(even) {
        background: #26293d;
      }
    }
  }

  .graficos-container {
    tbody {
      tr {
        td > div > h6 {
          color: #fff;
        }
      }
    }
  }

  .graficos-table > tbody > tr:not(.graficos-detail) {
    border-bottom: solid 1px #313452;
  }

  .highcharts-background {
    fill: #151B24;
  }

  .highcharts-tooltip-box .highcharts-label-box {
    fill: #fff;
    font-size: 12px;
  }

  .highcharts-range-input text{
    fill: #fff;
  }

  .modelos-container {
    .bg-white {
      &:first-child {
        background-color: #2b2f46 !important;
        color: #fff;
        border-radius: 4px;
        border: solid 1px #313452 !important;

        .row {
          &:last-child {
            .col {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  .metadataBox {
    background: #27293d;
    border: solid 1px #313452 !important;

    .value {
      color: #fff;
    }
  }

  // .MuiSelect-select.MuiSelect-select {
  //   color: #fff;
  //   background: #2b3045;
  // }

  .modelInputContainer {
    background: #27293d;
    border: solid 1px #313452;
    box-shadow: rgba(0, 4, 77, 0.1) 3px 3px 20px;

    input {
      color: #fff;
      background: #2b3045;

      &::-webkit-calendar-picker-indicator {
        color: #fff;
        opacity: 1;
      }

      svg {
        color: #fff;
      }
    }
  }

  .chat-container {
    background: #1e1e2f;
    
    .sidebar-chat {
      background: #26293d;
    }

    .rhap_container{
      background-color: #26293d !important;
    }

    .chat-author-name {
      color: #fff;
    }
    
    .chat-markup {
      color: #fff;
    }

    .left-head-menu-items {
      color: #fff;
    }
    
     .chat-search-input {
      background-color: #26293d;
      color: #fff;
      border-color: transparent;
     }
     
     .chat-file-preview-container{
       background-color: #26293d !important;
     }

    .chat-input-container-real {
      border: none;
      width: 100%;
      background-color: #26293d;
      font-size: 1.2rem;

      .chat-input {
        color: #fff;
      }

      .chat-input-btn-icon-btn {
        border-radius: 50%; 
        background-color: #1e1e2f;

        .chat-input-btn-icon-btn-text{
          color: #fff;
        }
    
        &:hover{
          background-color: #1e1e2f;
        }
    
        &:focus{
          background-color: #1e1e2f;
        }
      }
    }
  }

  .chat-container {
    background: #1e1e2f;
    
    .sidebar-chat {
      background: #26293d;
    }

    .rhap_container{
      background-color: #26293d !important;
    }
    
    .chat-author-name {
      color: #fff;
    }
    
    .chat-markup {
      color: #fff;
    }

    .left-head-menu-items {
      color: #fff;
    }
    
     .chat-search-input {
      background-color: #26293d;
      color: #fff;
      border-color: transparent;
     }
     
     .chat-file-preview-container{
       background-color: #26293d !important;
     }

    .chat-input-container-real {
      border: none;
      width: 100%;
      background-color: #26293d;
      font-size: 1.2rem;

      .chat-input {
        color: #fff;
      }

      .chat-input-btn-icon-btn {
        border-radius: 50%; 
        background-color: #1e1e2f;

        .chat-input-btn-icon-btn-text{
          color: #fff;
        }
    
        &:hover{
          background-color: #1e1e2f;
        }
    
        &:focus{
          background-color: #1e1e2f;
        }
      }
    }
  }

  .chat-container {
    background: #1e1e2f;
    
    .sidebar-chat {
      background: #26293d;
    }

    .rhap_container{
      background-color: #26293d !important;
    }
    
    .chat-author-name {
      color: #fff;
    }
    
    .chat-markup {
      color: #fff;
    }

    .left-head-menu-items {
      color: #fff;
    }
    
     .chat-search-input {
      background-color: #26293d;
      color: #fff;
      border-color: transparent;
     }
     
     .chat-file-preview-container{
       background-color: #26293d !important;
     }

    .chat-input-container-real {
      border: none;
      width: 100%;
      background-color: #26293d;
      font-size: 1.2rem;

      .chat-input {
        color: #fff;
      }

      .chat-input-btn-icon-btn {
        border-radius: 50%; 
        background-color: #1e1e2f;

        .chat-input-btn-icon-btn-text{
          color: #fff;
        }
    
        &:hover{
          background-color: #1e1e2f;
        }
    
        &:focus{
          background-color: #1e1e2f;
        }
      }
    }
  }

  .right-input-container-account{
    background: #363652;
    .sc-right-input-container-account{
      background-color: #1e1e30;
    }
  }

  .dashboard-sidebarRight{
    background-color: #1e1e30;
  }
    

  .sidebar {
    background-color: #1e1e30;
    height: 100%;
    // background: #1e1e30 url(/static/media/bgMenu.05e0b97c.png);

    &.sidebarDesktop{
      background: #1e1e2f !important;

      .nav-item-focused {
        color: #3de8a0 !important;
        background-color: #27293d !important;
        border-left: 3px solid;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        margin-left: -3px;
        width: calc(100% + 3px);
      }

      .nav-item-focused svg{
        color: #3de8a0 !important;
      }

      .nav-items-nested:hover{
        background-color: #27293d !important;
        color: #becef4 !important;
      }

      .nav-list-icon svg{
        color: #becef4;
      }

      .nav-items-nested:hover svg, .nav-items-nested:hover span{
        color: #becef4 !important;
      }
    }
  }

  .sidebar


  .juice-page-one {
    background: #27293d url(/static/media/bgMenu.05e0b97c.png);
    background-color: #27293d;
    height: 760px;
    background-size: cover;
    background-position: center;
  } 

  .juice-page-two {
    background: #27293d url('./assets/page_two.png');
    background-color: #27293d;
    height: 760px;
    background-size: cover;
    background-position: center;
  } 

  .juice-page-three {
    background: #27293d url('./assets/page_three.png');
    height: 760px;
    background-size: cover;
    background-position: center 52px;
    background-repeat: no-repeat;
    background-color: #27293d;
}

.juice-page-four {
    background: #27293d url('./assets/page_four.png');
    height: 760px;
    background-size: cover;
    background-position: center;
    background-color: #27293d;
}

  .navbar-new {
    background-color: #1e1e30 !important;
  }


  .indicadores-main-container {
    .helper-icon{
      color: #fff;
    }

    .indicator-dropdown{
      background-color: #27293d;

      svg {
        color: #fff !important;
      }
    }
  }

  @media (max-width: 768px) {
    .content, .fundamentos-container {
      background-color: #1d1e31;
    }

    .accountContainer{
      .MuiInputBase-root{
        padding-right: 0 !important;
        background: #363652;
        color: #909095;
        font-size: 16px;
        font-weight: 700;

        fieldset{
          border: 0px;
          margin: 0px;
        }

        .MuiOutlinedInput-input{
          padding: 0 14px !important;
          height: 41px !important;
        }
      }

      .account{
        &::after{
          left: 38px;
          background-color: #26293b;
          width: 90%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .accountContainer{
      .account{
        &::after{
          left: 19px;
          width: 89.9%;
          height: 100%;
        }
      }
    }
  }

  @media only screen and (device-width : 280px){
    .accountContainer{
      .account{
        &::after{
          left: 19px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .accountContainer{
      .MuiInputBase-root{
        padding-right: 0 !important;
        background: #363652;
        color: #909095;
        font-size: 16px;
        font-weight: 700;

        fieldset{
          border: 0px;
          margin: 0px;
        }

        .MuiOutlinedInput-input{
          padding: 0 14px !important;
          height: 41px !important;
        }
      }

      .account{
        &::after{
          left: 38px;
          background-color: #26293b;
          width: 90%;
          height: 100%;
        }
      }
    }
  }

  .highcharts-button{
    fill: #1F2733 !important;
    padding: 12px !important;
    border: 1px solid #fff !important;

    text{
      fill: #fff !important;
    }
  }


  .indicadores-section-one{
    background-color: #151b24 !important;

    .txt-black-white,
    .section-two-pt-on-phrase,
    .section-two-pt-one-txt,
    .indicadores-header-six-one,
    .indacadores-summary-right-upper,
    .indacadores-status-summary,
    .indicadores-header-icons,
    .indicadores-header-title{
      color: #fff !important;
    }

    .indacadores-summary-tag{
      background-color: #1F2733 !important;
    }
  }

  .highcharts-title{
    fill: #fff !important;
  }

  // .highcharts-tooltip-box{
  //   fill: #23232f !important;
  // }

  .highcharts-grid-line{
    stroke: #151b24;
  }


  .vernon-card {
    background-color: #23232f !important;
    border: 0px solid;
    height: 100%;
  
    .vernon-chips{
        background-color: #161620 !important;
        border: 0px solid #838289;
        color: #838289;
    }
  
    .vernon-title{
        color: #fff;
    }
  }
  
  .vernon-table {
    .vernon-text{
        color: #fff;
    }
  }

}


