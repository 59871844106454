.collections-container {
    display: flex;
    justify-content: center;
    color: #fff;
    margin-top: 20px;

    .collections-container-holder{
      width: 100%;
    }
}

.colletion-dropdown-lower-search-container {
    background-color: #fff;
    padding: 10px;
}

.drop-active {
    color: #292851;
    font-weight: bold;
}

.drop-in-active {
    color: #666666;
    font-weight: bold;
}

.collection-dropdown {
    position: relative;
    width: 200px;

    .colletion-dropdown-upper {
        border: 1px solid;
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        color: #292851;
        font-size: 16px;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    .colletion-dropdown-lower {  
        border: 1px solid;
        position: absolute;
        width: 100%;
        top: 50px;
        cursor: pointer;
        z-index: 1000;
        background-color: #fff;
        color: #292851;
        font-size: 16px;

        .collection-nft-item {
            width: 100%;
            padding: 10px;
            &:hover {
                color: #3DE8A0;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.inputOutlined{
  background: transparent;
  width: 100%;

  fieldset{
    border-color: #FFF;
  }

  .MuiInputBase-formControl:hover{
    fieldset{
      border-color: #FFF;
    }
  }
  
  input, label{
    color: #ccc;
  }
}

@media only screen and (device-width: 280px) {
    .collections-container-holder {
      max-width: 100px;
      width: 100%;
    }
}

@media only screen and (device-width: 360px) {
    .collections-container-holder {
      max-width: 300px;
      width: 100%;
    }
}

@media only screen and (device-width: 411px) {
    .collections-container-holder {
      max-width: 350px;
      width: 100%;
    }
}

@media only screen and (device-width: 320px) {
    .collections-container-holder {
      max-width: 280px;
      width: 100%;
    }
}

@media only screen and (device-width: 375px) {
    .collections-container-holder {
      max-width: 300px;
      width: 100%;
    }
}

@media only screen and (device-width: 414px) {
    .collections-container-holder {
      max-width: 380px;
      width: 100%;
    }
}

@media only screen and (device-width: 540px) {
    .collections-container-holder {
      max-width: 490px;
      width: 100%;
    }
}

@media only screen and (device-width: 768px) {
    .collections-container-holder {
      max-width: 700px;
      width: 100%;
    }
}

@media only screen and (device-width: 1024px) {
    .collections-container-holder {
      max-width: 900px;
      width: 100%;
    }
}

.collections-container-holder{
  .collections-container-grid{
    margin-left: 1px;
    margin-bottom: 10px;
  }
}

.collections-file {
  .highcharts-axis-title {
    font-size: 16px !important;
    text-transform: capitalize !important;
    color: #ffffff !important;
    fill:  #666666 !important;
  }

  .highcharts-tooltip-box {
    fill: black !important;
    fill-opacity: 0.6 !important;
    stroke-width: 0 !important;
  }
}


